import { UseFormRegister, UseFormStateReturn } from 'react-hook-form';
import { MediaFile } from 'lib/services/mediaFilesServices';

type MediaFileInfoTabProps = {
  formState: UseFormStateReturn<MediaFile>;
  register: UseFormRegister<MediaFile>;
  isAudio: boolean;
};

export const MediaFileInfoTab: React.FC<MediaFileInfoTabProps> = ({
  register,
  formState,
  isAudio,
}) => {
  return (
    <section className="flex w-full border-b border-gray-200 p-4">
      <form className="flex mb-8">
        <div className="flex-col mr-5 space-y-6">
          <div className="w-full">
            <label className="text-sm text-gray-400" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              {...register('title')}
              className="w-full rounded p-2 selected:bg-white border-gray-200 border-2"
            />
            {formState.errors?.title && (
              <p className="text-red-500 text-xs mt-1">
                {formState.errors.title?.message}
              </p>
            )}
          </div>
          {isAudio && (
            <div className="w-full">
              <label className="text-sm text-gray-400" htmlFor="title">
                Duration Text (optional)
              </label>
              <input
                type="text"
                {...register('durationText')}
                className="w-full rounded p-2 selected:bg-white border-gray-200 border-2"
              />
              {formState.errors?.durationText && (
                <p className="text-red-500 text-xs mt-1">
                  {formState.errors.durationText?.message}
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </section>
  );
};
