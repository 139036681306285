function useExportExcel(getExportData: () => Promise<any>) {
  const handleDownload = (binaryString: string, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([binaryString]));

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
  };

  const onExportButtonClick = async () => {
    const exportResponse = await getExportData();
    if (exportResponse)
      handleDownload(
        exportResponse.binaryString,
        exportResponse.fileName
      );
  };

  return { onExportButtonClick };
}

export default useExportExcel;
