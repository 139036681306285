export enum USER_ROLES {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  TESTER = 'ROLE_TESTER',
}

export enum DAYS_OF_WEEK {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday' ,
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}
