import classNames from 'classnames';
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiOutlineUpload, HiX } from 'react-icons/hi';
import { showToast } from 'lib/toast';
import { MediaFile, mediaFilesService } from 'lib/services/mediaFilesServices';
import { humanFileSize } from 'lib/utils/humanFileSize';
import { baseBackendErrorHandler } from 'components/common/ErrorHandler';
import { BackendError } from 'lib/types/entities';

export type CreateMediaFileModalProps = {
  readonly closeModal: () => void;
  readonly onCreate: () => void;
};
const ALLOWED_MEDIA_TYPES = ['audio', 'video', 'image'];

export const CreateMediaFileModal: React.FC<CreateMediaFileModalProps> = ({
  closeModal,
  onCreate,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { postFile } = mediaFilesService();
  const { register, handleSubmit, formState, setError } = useForm<MediaFile>({
    mode: 'onChange',
  });
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onSubmit = async (form: MediaFile) => {
    const trimmedTitle = form.title.trim();
    if (!trimmedTitle) {
      setError('title', { message: 'Title field is required' });
      return;
    }
    if (!uploadedFile) {
      showToast({
        type: 'error',
        title: 'Please upload file to continue!',
        message: 'Choose file to upload and try again.',
      });
      return;
    }

    const uploadedFileType = uploadedFile.type.split('/')?.[0];
    const fileFormData = new FormData();

    fileFormData.append('file', uploadedFile);
    fileFormData.append('type', uploadedFileType);
    fileFormData.append('bucketType', 'public');
    fileFormData.append('title', trimmedTitle);
    fileFormData.append('durationText', form.durationText?.trim() || '');

    if (!ALLOWED_MEDIA_TYPES.includes(uploadedFileType)) {
      showToast({
        type: 'warning',
        title: 'Wrong file type!',
        message: 'Please upload a correct file.',
      });
      setUploadedFile(null);
      return;
    }
    try {
      await postFile({
        data: fileFormData,
      });
      closeModal();
      onCreate();
      showToast({
        type: 'success',
        title: 'Upload was successful!',
        message: 'The file has been uploaded successfully!',
      });
    } catch (e) {
      const errorResponse = e as {
        data?: BackendError;
        status: number;
      };
      if (errorResponse?.data?.violations && errorResponse.status === 422) {
        baseBackendErrorHandler(errorResponse.data.violations, setError);
        return;
      }
      if (errorResponse.status === 500) {
        showToast({
          type: 'error',
          title: 'Wrong file type!',
          message: 'Please upload a correct file.',
        });
      } else {
        showToast({
          type: 'error',
          title: 'Something went wrong with file upload!',
          message: 'Try again later.',
        });
      }
    }
  };

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0]) return;
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size >= 50000000) {
      showToast({
        type: 'error',
        title: 'File is too large!',
        message: 'File size should be less than 50MB.',
      });
      setUploadedFile(null);
      return;
    }
    setUploadedFile(fileUploaded);
  };

  const handleUploadAreaClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <form className="flex flex-col p-10 rounded w-[100%] min-w-[700px]">
      <p className="text-2xl font-semibold pb-6">Add File</p>
      <div className="flex flex-col relative mb-4">
        <label
          htmlFor="email"
          className="text-gray-400 pb-2 text-sm"
        >
          Title
        </label>
        <input
          type="text"
          className="h-10 px-2 py-2 border-gray-200 border-solid border-2 rounded"
          {...register('title', { maxLength: 255 })}
          id="title"
        />
        {formState.errors?.title && (
          <p className="text-red-500 text-xs mt-1">
            {formState.errors.title?.message}
          </p>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={hiddenFileInput}
          onChange={(event: any) => uploadFile(event)}
        />
      </div>
      <div className="flex flex-col relative mb-4">
        <label
          htmlFor="email"
          className="text-gray-400 pb-2 text-sm"
        >
          Duration text (optional)
        </label>
        <input
          type="text"
          className="h-10 px-2 py-2 border-gray-200 border-solid border-2 rounded"
          {...register('durationText', { maxLength: 255 })}
          id="durationText"
        />
        {formState.errors?.durationText && (
          <p className="text-red-500 text-xs mt-1">
            {formState.errors.durationText?.message}
          </p>
        )}
      </div>
      {uploadedFile ? (
        <div className="mt-7 p-4 rounded bg-gray-200 flex items-center justify-between">
          <div className="text--500 flex">
            <span>File {uploadedFile.name} </span>
            <p className="px-2">&#x2022;</p>

            <span className="text-gray-400">
              {humanFileSize(uploadedFile.size)}
            </span>
          </div>
          <HiX
            className="w-5 h-5"
            onClick={() => setUploadedFile(null)}
          />
        </div>
      ) : (
        <div
          className={classNames(
            'flex flex-col border-dashed border-gray-400 items-center h-full border cursor-pointer  min-h-[200px]',
            {
              'bg-green-100': !!uploadedFile,
            }
          )}
          onClick={handleUploadAreaClick}
        >
          <HiOutlineUpload
            color="gray"
            fontSize={'25px'}
            className="mt-10"
          />
          <div className="flex mt-7">
            <p className="text-blue-500">Choose file &nbsp;</p>
            to upload
          </div>
          <p className="text-gray-400 mt-4 mb-10">
            File size should be less than 50MB.
          </p>
        </div>
      )}
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="text-blue-500 bg-white px-4 py-2 rounded mr-2"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-blue-500 text-white px-10 py-2 rounded"
          onClick={handleSubmit(onSubmit, (e) =>
            Object.keys(e).forEach((key: string) =>
              setError(key as keyof MediaFile, {
                message: 'Length must not exceed 255 characters.',
              })
            )
          )}
        >
          Continue
        </button>
      </div>
    </form>
  );
};
