import { HiAdjustments, HiSearch, HiTable } from 'react-icons/hi';
import { Modal } from '../../../components/modals/Modal';
import { ModalMode } from '../../../components/layouts/ModalLayout';
import { MainLayout } from '../../../components/layouts/MainLayout';
import { UsersTable } from '../../../components/tables/UsersTable';
import { UserInfoModal } from '../../../components/modals/UserInfoModal';
import { PaginationFooter } from 'components/common/PaginationFooter/PaginationFooter';
import { UserFiltersPopUp } from 'components/modals/UserFiltersPopUp';
import classNames from 'classnames';
import { UserSearchPopUp } from 'components/modals/UserSearchPopUp';
import useUserManagement from 'lib/hooks/useUserManagement';

export const UsersPage = () => {
  const {
    isDetailVisible,
    isFilterDropdownVisible,
    isSearchDropdownVisible,
    selectedUserId,
    activeFilters,
    currentUserParams,
    updateCurrentFilters,
    openModalAndSetId,
    closeModal,
    getUsersRequest,
    setIsSearchDropdownVisible,
    setIsFilterDropdownVisible,
    onUserUpdate,
    onSortClick,
    setIsDeleteModalVisible,
    onUserDelete,
    onDeleteModalOpen,
    isDeleteModalVisible,
    onExportButtonClick,
  } = useUserManagement();

  return (
    <MainLayout>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> Users</p>
          <div className="flex text-gray-500 relative">
            <button
              className="border border-gray-100 rounded p-2 flex items-center test-sm mr-4"
              onClick={() =>
                setIsSearchDropdownVisible(!isSearchDropdownVisible)
              }
            >
              <HiSearch className="fill-gray-500 mx-1" />
              Search
            </button>
            <button
              className="border border-gray-100 rounded py-2 px-4 flex items-center test-sm mr-4"
              onClick={onExportButtonClick}
            >
              <HiTable className="fill-gray-500 mx-1" />
              Download in Excel
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center test-sm',
                {
                  'bg-gray-100 text-black':
                    isFilterDropdownVisible || activeFilters > 0,
                }
              )}
              onClick={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
            >
              <HiAdjustments
                className={classNames('mx-1', {
                  'fill-gray-500': activeFilters === 0,
                  'fill-black text-black font-bold':
                    isFilterDropdownVisible || activeFilters > 0,
                })}
              />
              Filters {activeFilters ? `(${activeFilters})` : ''}
            </button>
            <UserFiltersPopUp
              isVisible={isFilterDropdownVisible}
              onClose={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
              onChangeFilters={updateCurrentFilters}
            />
            <UserSearchPopUp
              isVisible={isSearchDropdownVisible}
              onSearchItemClick={openModalAndSetId}
            />
          </div>
        </div>
        <UsersTable
          onItemClick={openModalAndSetId}
          onSortClick={onSortClick}
        />
        <PaginationFooter
          itemsPerPage={currentUserParams.itemsPerPage}
          currentPage={currentUserParams.page}
          totalItems={currentUserParams.totalItems}
          onPageClick={(newPage) => getUsersRequest(newPage)}
        />
      </div>
      <Modal
        mode={ModalMode.right}
        isModalVisible={isDetailVisible}
      >
        <UserInfoModal
          userId={selectedUserId}
          closeModal={closeModal}
          onDelete={onDeleteModalOpen}
          onUpdate={onUserUpdate}
          onTestStatusUpdate={() => getUsersRequest()}
        />
      </Modal>
      <Modal
        mode={ModalMode.center}
        isModalVisible={isDeleteModalVisible}
      >
        <div className="px-8 py-6">
          <p className="text-lg">Are you sure you want to delete the user?</p>
          <div className="bg-white h-16 flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-blue-500 p-2 rounded"
              onClick={() => setIsDeleteModalVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded"
              onClick={() => onUserDelete()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};
