import axios, { AxiosRequestHeaders, AxiosResponse, AxiosError } from 'axios';
import { useStore } from 'lib/store';

const api = ({ headers = {} }) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  const apiToken = localStorage.getItem('authToken');
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 10000,
    headers: {
      ...defaultHeaders,
      ...(apiToken && {
        Authorization: `Bearer ${apiToken}`,
      }),
      ...headers,
    },
    responseType: 'json',
  });

  client.interceptors.request.use(
    (request) => {
      useStore.getState().updateIsFetching(true);
      return request;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      useStore.getState().updateIsFetching(false);
      return response;
    },
    (error: AxiosError) => {
      useStore.getState().updateIsFetching(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
      return Promise.reject(error.response);
    }
  );

  return client;
};

export default api;

export type axiosRequest<T = {}> = ({
  headers,
  params,
}: {
  headers?: AxiosRequestHeaders;
  params?: Record<string, any>;
} & T) => Promise<AxiosResponse>;

export type PagingParams = { page?: number; itemsPerPage?: number };
