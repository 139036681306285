import { TabFilters, TableSorting } from 'lib/types/entities';
import api from './api';

export type CardPlayResponse = {
  '@id': string;
  '@type': string;
  status: string;
  cardPlaylist: {
    '@context': string;
    '@id': string;
    '@type': string;
    user: string;
  };
  card: {
    '@context': string;
    '@id': string;
    '@type': string;
    sequenceNumber: number;
    title: string;
  };
  isDeleted: true;
  updatedAt: '2023-08-15T22:30:01.359Z';
};

export type GetCardPlaysResponse = {
  'hydra:member': CardPlayResponse[];
  'hydra:totalItems': number;
};
export type GetCardsRequestParams = {
  params: {
    page: number;
    itemsPerPage: number;
    order?: TableSorting;
    'cardPlaylist.user'?: string;
  } & TabFilters;
};

export const cardPlaysService = () => {
  const getCardPlays = async ({
    params = { page: 1, itemsPerPage: 30, order: { id: 'desc' } },
  }: GetCardsRequestParams) => {
    return api({}).get<GetCardPlaysResponse>('/card_plays/statistics', {
      params,
    });
  };

  return {
    getCardPlays,
  };
};
