import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contextClass } from './lib/toast';
import { LoadingIndicator } from 'components/common/LoadingIndicator/LoadingIndicator';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      toastClassName={(context) =>
        contextClass[context?.type || 'default'] +
        ' relative flex py-3 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2 text-gray-700'
      }
    />
    <div id="root-modal" />
    <LoadingIndicator />
  </React.StrictMode>
);
