import React, { useEffect, useState } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { columnList } from './Data';
import { UserCardsTableRow } from '../../../lib/types/tables';
import { TableSorting } from 'lib/types/entities';
import classNames from 'classnames';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';

export type UserCardPlaysTableProps = {
  onItemClick: (value: string) => void;
  onSortClick: (value: TableSorting) => void;
  cardsTableData: UserCardsTableRow[];
};
export const UserCardPlaysTable: React.FC<UserCardPlaysTableProps> = ({
  onItemClick,
  onSortClick,
  cardsTableData,
}) => {
  const [currentSorting, setCurrentSorting] = useState<TableSorting>({
    sequenceNumber: 'desc',
  });
  const data = React.useMemo<UserCardsTableRow[]>(
    () => cardsTableData,
    [cardsTableData]
  );
  const columns = React.useMemo<Column<UserCardsTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns: ['id'] };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  const onColumnClick = (column: string) => {
    if (column === 'title') {
      if (currentSorting['card.title'] === 'desc') {
        setCurrentSorting({
          'card.title': 'asc',
        });
        return;
      } else {
        setCurrentSorting({
          'card.title': 'desc',
        });
        return;
      }
    }

    if (column === 'sequenceNumber') {
      if (currentSorting['card.sequenceNumber'] === 'desc') {
        setCurrentSorting({
          'card.sequenceNumber': 'asc',
        });
        return;
      } else {
        setCurrentSorting({
          'card.sequenceNumber': 'desc',
        });
        return;
      }
    }

    if (currentSorting[column] === 'desc') {
      setCurrentSorting({
        [column]: 'asc',
      });
    } else {
      setCurrentSorting({
        [column]: 'desc',
      });
    }
  };

  useEffect(() => {
    onSortClick(currentSorting);
  }, [currentSorting]);

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs border border-gray-200"
    >
      <thead className="h-11 border-b border-gray-200 px-4">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className="h-10 px-2"
              >
                <div
                  className={classNames(
                    'flex justify-start items-center cursor-pointer',
                    {
                      'text-blue-500':
                        column.id === 'title' || column.id === 'sequenceNumber'
                          ? currentSorting[`card.${column.id}`]
                          : currentSorting[column.id],
                    }
                  )}
                  onClick={() => {
                    onColumnClick(column.id);
                  }}
                >
                  {column.render('Header')}
                  <p className="mx-1 h-4 relative">
                    <GoTriangleUp
                      className={classNames('w-2 h-2 top-[1px] absolute', {
                        hidden:
                          column.id === 'title' ||
                          column.id === 'sequenceNumber'
                            ? currentSorting[`card.${column.id}`]
                            : currentSorting[column.id],
                      })}
                    />
                    <GoTriangleDown
                      className={classNames('w-2 h-2 bottom-[1px] absolute', {
                        hidden:
                          column.id === 'title' ||
                          column.id === 'sequenceNumber'
                            ? currentSorting[`card.${column.id}`]
                            : currentSorting[column.id],
                      })}
                    />
                    <HiArrowDown
                      className={classNames('w-4 h-4 bottom-[1px] absolute', {
                        hidden:
                          column.id === 'title' ||
                          column.id === 'sequenceNumber'
                            ? currentSorting[`card.${column.id}`] !== 'desc'
                            : currentSorting[column.id] !== 'desc',
                      })}
                    />
                    <HiArrowUp
                      className={classNames('w-4 h-4 bottom-[1px] absolute', {
                        hidden:
                          column.id === 'title' ||
                          column.id === 'sequenceNumber'
                            ? currentSorting[`card.${column.id}`] !== 'asc'
                            : currentSorting[column.id] !== 'asc',
                      })}
                    />
                  </p>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={(event) => {
                const target = event.target as Element;
                if (target.tagName !== 'INPUT' && target.tagName !== 'BUTTON') {
                  onItemClick(row.values.id);
                }
              }}
              className="h-10 text-left border-b hover:bg-blue-100"
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={classNames('px-4 mr-auto', {
                      capitalize: cell.column.id === 'status',
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
