export type SORT_OPTIONS = 'asc' | 'desc';
export type Direction = 'up' | 'down';
export type UserFilters = {
  signupOrigin: string;
  startDate: Date | null;
  endDate: Date | null;
  by_roles: TesterFilterOption | null;
};
export type FeedbackFilters = {
  startDate: Date | null;
  endDate: Date | null;
}
export enum CardPromptTypes {
  text = 'text',
  audio_text = 'audio_text',
}
export enum TesterFilterOption {
  TESTER = 'tester',
  NO_TESTER = 'no_tester',
}
export type CardPromptType = CardPromptTypes.text | CardPromptTypes.audio_text;
export type TabFilters = Partial<{
  type: string;
  title: string;
  responseType: string;
  active: boolean | null;
  advanced: boolean | null;
  frequency: string;
  draft: boolean | null;
}>;
export type TableSorting = Partial<{ [x: string]: SORT_OPTIONS }>;
export enum CardTypes {
  'mindfulness' = 'MINDFUL',
  'affirmation' = 'AFFIRMATIONAL',
  'gratitude' = 'GRATEFUL',
  'intention' = 'INTENTIONAL',
  'kudos' = 'KUDOS',
}
export type BackendError = {
  '@context': string;
  '@type': string;
  'hydra:description': string;
  'hydra:title': string;
  violations?: Violation[];
};
export type Violation = {
  code: string;
  message: string;
  propertyPath: string;
}
export type FeedbackQuestion = {
  step: number;
  question: string;
  answer: string;
};