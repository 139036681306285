import classNames from 'classnames';
import { ContentPage } from 'lib/services/contentPagesServices';
import { useMemo } from 'react';

type ContentPagesListProps = {
  pages: ContentPage[];
  selectedPageId: string;
  onSelectPage: (id: string) => void;
};

export const ContentPagesList: React.FC<ContentPagesListProps> = ({
  pages,
  selectedPageId,
  onSelectPage,
}) => {
  const mockedUrls: ContentPage[] = [
    {
      '@id': 'faq',
      urlPath: 'faq',
      publicVisibility: true,
      contentTitle: '',
      contentBody: '',
    },
  ];

  const renderUrlList = useMemo(
    () =>
      pages.concat(mockedUrls).map((page) => {
        return (
          <li
            key={page['@id']}
            onClick={() => onSelectPage(page['@id'])}
            className="mx-2 whitespace-nowrap overflow-y-visible max-w-[250px] min-w-[30px] relative"
          >
            <p className="overflow-x-hidden text-ellipsis">/{page.urlPath}</p>
            <p
              className={classNames(
                'h-[2px] bg-black rounded-full absolute z-10 -bottom-[10px] left-0 right-0',
                { hidden: selectedPageId !== page['@id'] }
              )}
            />
          </li>
        );
      }),
    [pages, selectedPageId]
  );

  return (
    <ul className="w-full h-full flex items-center mx-5 text-sm text-gray-900 overflow-y-hidden cursor-pointer overflow-x-auto">
      {renderUrlList}
    </ul>
  );
};
