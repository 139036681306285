import { FeedbackQuestion } from 'lib/types/entities';

type FeedbackInfoTabProps = {
  details: FeedbackQuestion[];
};

export const FeedbackInfoTab: React.FC<FeedbackInfoTabProps> = ({
  details,
}) => {
  return (
    <section className="flex w-full border-b border-gray-200 bg-white p-4">
      <form className="flex mb-8">
        <div className="flex-col mr-5 space-y-6">
          <div className="w-full">
            {!!details.length &&
              details
                .sort(
                  (prevDetail, currentDetail) =>
                    prevDetail.step + currentDetail.step
                )
                .map((detailTopic: FeedbackQuestion) => {
                  return (
                    <div className="mb-4 max-w-xl">
                      <p className="text-lg font-semibold whitespace-normal mb-2">
                        {detailTopic.question}
                      </p>
                      <p className="italic pl-4">{detailTopic.answer}</p>
                    </div>
                  );
                })}
          </div>
        </div>
      </form>
    </section>
  );
};
