import { CellProps, Column } from 'react-table';
import { CardsTableRow } from '../../../lib/types/tables';

export const columnList: Column<CardsTableRow>[] = [
  {
    Header: 'TAB ID',
    accessor: 'sequenceNumber',
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'CARD TYPE',
    accessor: 'type',
  },
  {
    Header: 'TAB TITLE',
    accessor: 'title',
  },
  {
    Header: 'STATUS',
    accessor: 'active',
  },
  {
    Header: 'PROMPT MEDIA',
    accessor: 'mediaType',
  },
  {
    Header: 'RESP MEDIA',
    accessor: 'responseType',
  },
  {
    Header: 'ADVANCED?',
    accessor: 'advanced',
  },
  {
    Header: 'FREQ',
    accessor: 'frequency',
  },
  {
    Header: 'SIMIL GROUP',
    accessor: 'similiatityGroup',
    Cell: (cell: CellProps<CardsTableRow>) => {
      return cell.value === 0 ? 'NA' : cell.value;
    },
  },
  {
    Header: 'LIMIT',
    accessor: 'limit',
  },
];
