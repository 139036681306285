import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { userService } from 'lib/services/userServices';
import { SearchUserResults } from './SearchUserResults';
import { UserProfileShape } from 'lib/types/store';
import { HiX } from 'react-icons/hi';

type UserFiltersPopUpProps = {
  isVisible: boolean;
  onSearchItemClick: (id: string) => void;
};

const SEARCH_BY_OPTIONS = [
  { label: 'ID', value: 'firebaseUser' },
  { label: 'Name', value: 'userProfile.firstName' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phone' },
];

export const UserSearchPopUp: React.FC<UserFiltersPopUpProps> = ({
  isVisible,
  onSearchItemClick,
}) => {
  const { getUsers } = userService();
  const [currentSearchBy, setCurrentSearchBy] = useState(
    'userProfile.firstName'
  );
  const [currentSearchValue, setCurrentSearchValue] = useState('');
  const [currentSearchData, setCurrentSearchData] = useState<
    UserProfileShape[]
  >([]);

  const handleApplySearch = async ({
    searchBy,
    searchValue,
  }: {
    [x: string]: string;
  }) => {
    const actualSearchBy = searchBy || currentSearchBy;
    const actualSearchValue = searchValue || currentSearchValue;

    if (actualSearchBy === 'userProfile.firstName') {
      const userFirstNameResponse = getUsers({
        params: {
          'userProfile.firstName': actualSearchValue,
          itemsPerPage: 100,
        },
      });
      const userLastNameResponse = getUsers({
        params: {
          'userProfile.lastName': actualSearchValue,
          itemsPerPage: 100,
        },
      });
      const [userFirstNameResult, userLastNameResult] = await Promise.all([
        userFirstNameResponse,
        userLastNameResponse,
      ]);
      const combinedResults = [
        ...userFirstNameResult.data['hydra:member'],
        ...userLastNameResult.data['hydra:member'],
      ];
      setCurrentSearchData(combinedResults);
      return;
    }
    const userResponse = await getUsers({
      params: { [actualSearchBy]: actualSearchValue, itemsPerPage: 100 },
    });

    setCurrentSearchData(userResponse.data['hydra:member']);
  };

  useEffect(() => {
    if (!isVisible) {
      setCurrentSearchValue('');
      setCurrentSearchBy('userProfile.firstName');
      setCurrentSearchData([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (currentSearchValue.length < 3) {
      setCurrentSearchData([]);
    }
  }, [currentSearchValue]);

  return (
    <div
      id="dropdown"
      className={classNames(
        'absolute z-10 w-[400px] bg-white rounded shadow top-14 right-24 p-1',
        { hidden: !isVisible }
      )}
    >
      <div className="flex justify-between items-center text-gray-400 font-light text-xs p-2">
        <div className="w-full relative first-letter:border-l">
          <input
            type="text"
            placeholder="Search in Users Table"
            value={currentSearchValue}
            className="w-full py-2 px-2"
            onChange={(e) => {
              setCurrentSearchValue(e.target.value);
              if (e.target.value.length >= 3) {
                handleApplySearch({ searchValue: e.target.value });
              }
            }}
          />
          <HiX
            className="w-4 h-4 absolute top-2 right-2"
            onClick={() => {
              setCurrentSearchValue('');
              setCurrentSearchData([]);
            }}
          />
        </div>

        <p className="w-32 text-xs text-black font-semibold pl-4">Search By</p>
        <select
          name="searchBy"
          value={currentSearchBy}
          placeholder="userProfile.firstName"
          id="searchBy"
          className="w-20 bg-white cursor-pointer"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setCurrentSearchData([]);
            setCurrentSearchBy(event.target.value);
            if (currentSearchValue) {
              handleApplySearch({ searchBy: event.target.value });
            }
          }}
        >
          {SEARCH_BY_OPTIONS.map((origin) => (
            <option key={origin.label} value={origin.value}>
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <div className="w-full pt-4 p-2">
        <SearchUserResults
          onItemClick={onSearchItemClick}
          searchResultData={currentSearchData}
          searchBy={currentSearchBy}
          searchValue={currentSearchValue}
        />
      </div>
    </div>
  );
};
