import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { VersionInput } from 'components/common/VersionInput';
import { MainLayout } from 'components/layouts/MainLayout';
import useRemoteConfig from 'lib/hooks/useRemoteConfig';

export type RemoteConfigSchema = {
  iosLatest: string;
  iosMinNotBlocked: string;
  androidLatest: string;
  androidMinNotBlocked: string;
};

export const RemoteConfigPage = () => {
  const { handleSubmit, onSubmit, control, formState, isLoading } =
    useRemoteConfig();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <MainLayout>
      <div className="flex flex-col min-h-full">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> Config </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <p className="text-lg mb-4 px-2">iOS</p>
            <VersionInput
              labelStyle="text-sm text-gray-400 mb-4"
              inputStyle="rounded mt-2 py-2 px-4 block  border-gray-200 border-2"
              errorStyle="text-sm text-red-400 absolute -bottom-5"
              controlStyle="p-2 flex bg-blue-30 mb-4 w-full relative"
              name="iosLatest"
              label="Latest version to update to"
              control={control}
            />
            <VersionInput
              labelStyle="text-sm text-gray-400 mb-4"
              inputStyle="rounded mt-2 py-2 px-4 block  border-gray-200 border-2"
              errorStyle="text-sm text-red-400 absolute -bottom-5"
              controlStyle="p-2 flex bg-blue-30 mb-4 w-full relative"
              name="iosMinNotBlocked"
              label="Minimal not blocked version"
              control={control}
            />
            <p className="text-lg mb-4 px-2 mt-8">Android</p>
            <VersionInput
              labelStyle="text-sm text-gray-400 mb-4"
              inputStyle="rounded mt-2 py-2 px-4 block border-gray-200 border-2"
              errorStyle="text-sm text-red-400 absolute -bottom-5"
              controlStyle="p-2 flex bg-blue-30 mb-4 w-full relative"
              name="androidLatest"
              label="Latest version to update to"
              control={control}
            />
            <VersionInput
              labelStyle="text-sm text-gray-400 mb-4"
              inputStyle="rounded mt-2 py-2 px-4 block border-gray-200 border-2"
              errorStyle="text-sm text-red-400 absolute -bottom-5"
              controlStyle="p-2 flex bg-blue-30 mb-4 w-full relative"
              name="androidMinNotBlocked"
              label="Minimal not blocked version"
              control={control}
            />
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white py-4 px-8 rounded-xl m-2"
              disabled={!formState.isValid}
            >
              Save new config
            </button>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};
