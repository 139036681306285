import { Column } from 'react-table';
import { UserCardsTableRow } from '../../../lib/types/tables';

export const columnList: Column<UserCardsTableRow>[] = [
  {
    Header: 'TAB ID',
    accessor: 'sequenceNumber',
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'TAB TITLE',
    accessor: 'title',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'TIME & DATE',
    accessor: 'updatedAt',
  },
];
