import { showToast } from 'lib/toast';
import { UseFormSetError } from 'react-hook-form';

export const baseBackendErrorHandler = (
  violations: { message: string; propertyPath: string; code: string }[],
  setError: UseFormSetError<any>
) => {
  showToast({
    type: 'error',
    title: 'Form is not filled correctly!',
    message: 'Please fill all required values or change wrong ones.',
  });
  return violations?.forEach(({ message, propertyPath }) => {
    setError(propertyPath, {
      message,
    });
  });
};
