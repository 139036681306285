export const formatDate = (date: Date) => {
  const month = padValue(date.getMonth() + 1);
  const day = padValue(date.getDate());
  const year = date.getFullYear();

  return `${month}.${day}.${year}`;
};
export const formatDateWithTime = (date: string) => {
  const newDate = new Date(date);

  const sMonth = padValue(newDate.getMonth() + 1);
  const sDay = padValue(newDate.getDate());
  const sYear = newDate.getFullYear();
  let sHour: string | number = newDate.getHours();
  const sMinute = padValue(newDate.getMinutes());
  let sAMPM = 'a.m.';

  const iHourCheck = parseInt(String(sHour));

  if (iHourCheck > 12) {
    sAMPM = 'p.m.';
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = '12';
  }

  sHour = padValue(Number(sHour));

  return (
    sMonth +
    '.' +
    sDay +
    '.' +
    sYear +
    ' ' +
    sHour +
    ':' +
    sMinute +
    ' ' +
    sAMPM
  );
};

function padValue(value: number) {
  return value < 10 ? '0' + value : value;
}

export const formatSeconds = (totalSeconds: number) => {
  return new Date(totalSeconds * 1000).toISOString().substring(14, 19);
};
