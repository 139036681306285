import { User } from 'firebase/auth';
import { CardsTableData, FeedbackTableRow, HistoryTableData } from './tables';
import { FrequentlyAskedQuestion } from 'lib/services/FAQServices';
import { ContentPage } from 'lib/services/contentPagesServices';
import { USER_ROLES } from 'lib/enums';

export type EntityItem = {
  id: string;
  name: string;
};

export enum SignInSteps {
  EMAIL = 'EMAIL',
  OTP = 'OTP',
}
export type FaqQuestion = FrequentlyAskedQuestion;
export type UserProfileShape = {
  id: string;
  '@id': string;
  username?: string;
  phone?: string;
  firebaseUser?: string;
  roles?: USER_ROLES[];
  name?: string;
  email?: string;
  password?: string;
  roleId?: string;
  isTester?: string;
  userProfile?: {
    personTitle?: string;
    firstName?: string;
    lastName?: string;
    birthdate?: string;
    stateCode?: string;
    city?: string;
    countryCode?: string;
    tabsPerDay?: string;
    timezone?: string;
    subscriptionStatus?: string;
    revenueCatLink?: string;
    subscriptionType?: string;
    paymentStatus?: string;
    subscriptionDate?: string;
  };
  createdAt?: string;
  provider?: string;
};

export type UserProfile = User & {
  phone?: string;
  userProfile?: {
    firstName?: string;
    lastName?: string;
    personTitle?: string;
    timezone?: string;
    city?: string;
    country?: string;
    stateCode?: string;
  };
};

export type UsersSlice = {
  usersTableData: UserProfileShape[];
  updateUsersTableData: (newData: UserProfileShape[]) => void;
  cleanUsersTableData: () => void;
};

export type MediaFilesSlice = {
  mediaFilesTableData: any[];
  updateMediaFilesTableData: (newData: any[]) => void;
  cleanMediaFilesTableData: () => void;
};

export type FeedbackSlice = {
  feedbackTableData: FeedbackTableRow[];
  updateFeedbackTableData: (newData: FeedbackTableRow[]) => void;
  cleanFeedbackTableData: () => void;
};

export type UserProfileSlice = {
  userProfile: UserProfile | null;
  isFirebaseConnected: boolean | null;
  updateIsFirebaseConnected: (newData: boolean | null) => void;
  updateUserProfile: (newData: UserProfile | null) => void;
  currentSignInStep: SignInSteps;
  updateCurrentSignInStep: (newData: SignInSteps) => void;
  cleanUserProfile: () => void;
};

export type StaticPagesSlice = {
  contentPages: ContentPage[];
  updateContentPages: (newData: ContentPage[]) => void;
};

export type LoaderSlice = {
  isFetching: boolean;
  updateIsFetching: (newData: boolean) => void;
};

export type FAQSlice = {
  faqQuestions: FaqQuestion[];
  faqContent: string;
  updateFaqQuestions: (newData: FaqQuestion[]) => void;
  updateFaqContent: (newData: string) => void;
};

export type HistorySlice = {
  historyTableData: HistoryTableData;
  updateHistoryTableData: (newData: HistoryTableData) => void;
  cleanHistoryTableData: () => void;
};
export type CardsSlice = {
  cardsTableData: CardsTableData;
  updateCardsTableData: (newData: CardsTableData) => void;
  cleanCardsTableData: () => void;
};

export type EntityListSlice = {
  roleList: EntityItem[];
  locationList: EntityItem[];
  subscriptionTypeList: EntityItem[];
  subscriptionStatusList: EntityItem[];
  payStatusList: EntityItem[];
  cardStatusList: EntityItem[];
  signupOriginList: EntityItem[];
  getRoleById: (value: string) => string | null;
  getSubscriptionTypeById: (value: string) => string | null;
  getSubscriptionStatusById: (value: string) => string | null;
  getPayStatusById: (value: string) => string | null;
  getCardStatusById: (value: string) => string | null;
  getSignupOriginById: (value: string) => string | null;
  updateLocationList: (value: EntityItem[]) => void;
};
