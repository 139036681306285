import ReactQuill, { ReactQuillProps, Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './overwriteQuill.css';

let Size = Quill.import('attributors/style/size');
Size.whitelist = ['12pt', '16pt', '18pt', '20pt', '24pt', '48pt', '64pt'];
Quill.register(Size, true);

export const TextEditor: React.FC<ReactQuillProps> = ({ ...props }) => {
  return (
    <ReactQuill
      theme="snow"
      {...props}
      modules={{
        toolbar: [
          [
            { font: [] },
            {
              size: [
                '12pt',
                false,
                '16pt',
                '18pt',
                '20pt',
                '24pt',
                '48pt',
                '64pt',
              ],
            },
          ],
          ['bold', 'italic', 'underline'],
          [{ indent: '-1' }, { indent: '+1' }],
          ['align'],
          [{ align: 'right' }],
          [{ align: 'center' }],
          [{ align: 'justify' }],
          ['link', 'image'],
        ],
      }}
    />
  );
};
