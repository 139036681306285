import { Column } from 'react-table';
import { FeedbackTableRow } from '../../../lib/types/tables';

export const columnList: Column<FeedbackTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'USER ID',
    accessor: 'user',
  },
  {
    Header: 'CANCELATION DATE',
    accessor: 'cancellationDate',
  },
];
