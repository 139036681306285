import {
  UseFormRegister,
  UseFormStateReturn,
  UseFormWatch,
} from 'react-hook-form';
import { UserProfileShape } from '../../../../lib/types/store';
import DatePicker from 'react-datepicker';

type UserInfoTabProps = {
  formState?: UseFormStateReturn<UserProfileShape>;
  register: UseFormRegister<UserProfileShape>;
  onUpdateDate: (value: string) => void;
  watch: UseFormWatch<UserProfileShape>;
  isTester: boolean;
  updateTestRole: () => void;
};

export const UserInfoTab: React.FC<UserInfoTabProps> = ({
  register,
  formState,
  onUpdateDate,
  watch,
  isTester,
  updateTestRole,
}) => {
  const currentBirthDate = watch('userProfile.birthdate');
  const validBirthDate = currentBirthDate ? new Date(currentBirthDate) : null;

  return (
    <section className="flex w-full border-b border-gray-200 p-4 bg-white">
      <form className="flex mb-8">
        <div className="flex-col mr-5 space-y-6">
          <div className="w-full">
            <label
              className="text-sm text-gray-400"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              {...register('email')}
              placeholder="example@email.com"
              className="w-full rounded p-2 selected:bg-white border-gray-200 border-2"
            />
            {formState?.errors.email?.message && (
              <p className="text-red-500 italic text-xs absolute">
                {formState?.errors.email?.message}
              </p>
            )}
          </div>
          <div className="w-full relative">
            <label
              className="text-sm text-gray-400"
              htmlFor="state"
            >
              State
            </label>
            <input
              type="text"
              placeholder="NY"
              {...register('userProfile.stateCode')}
              className="w-full rounded p-2 border-gray-200 border-2"
            />
          </div>
          <div className="w-full relative flex flex-col">
            <label
              className="text-sm text-gray-400"
              htmlFor="birthDay"
            >
              Birthday
            </label>
            <DatePicker
              selected={validBirthDate}
              onChange={(date) => {
                date && onUpdateDate(date.toISOString());
              }}
              startDate={new Date()}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              className="w-full rounded p-2 border-gray-200 border-2"
              placeholderText="MM/DD/YYYY"
            />
            {formState?.errors.userProfile?.birthdate?.message && (
              <p className="text-red-500 italic text-xs absolute">
                {formState?.errors.userProfile?.birthdate?.message}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="subscription"
              className="text-sm text-gray-400"
            >
              Tester role
            </label>
            <select
              id="isTester"
              value={isTester ? 'active' : ''}
              onChange={(e) => updateTestRole()}
              className="w-full h-[44px] rounded px-2 py-2.5 bg-white border-gray-200 border-2"
            >
              <option value="active">Active</option>
              <option value="">Not Active </option>
            </select>
          </div>
        </div>
        <div className="flex-col">
          <div className="mb-6">
            <label
              htmlFor="phone"
              className="text-sm text-gray-400"
            >
              Phone number
            </label>
            <input
              placeholder="+1 234 567 89 00"
              {...register('phone')}
              className="w-full rounded p-2 border-gray-200 border-2"
            />
            {formState?.errors.phone && (
              <p className="text-red-500 italic text-xs pb-1 absolute">
                {formState?.errors.phone?.message && 'Not valid phone'}
              </p>
            )}
          </div>
          <div className="w-full mb-5">
            <label
              htmlFor="country"
              className="text-sm text-gray-400"
            >
              Country
            </label>
            <input
              placeholder="US"
              {...register('userProfile.countryCode')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            />
          </div>
          <div className="w-full mb-6">
            <label
              htmlFor="city"
              className="text-sm text-gray-400"
            >
              City
            </label>
            <input
              placeholder="New York"
              {...register('userProfile.city')}
              className="w-full rounded p-2 border-gray-200 border-2 bg-white"
            />
          </div>
        </div>
      </form>
    </section>
  );
};
