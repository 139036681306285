import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from 'lib/store';
import { firebaseAuth } from 'lib/utils/firebaseConfig';

type PrivateRoutesProps = { redirectPath?: string };

export const PrivateRoutes: React.FC<PrivateRoutesProps> = ({
  redirectPath = '/',
}) => {
  const { isFirebaseConnected } = useStore((state) => ({
    isFirebaseConnected: state.isFirebaseConnected,
  }));

  if (firebaseAuth.currentUser) {
    return <Outlet />;
  }

  return isFirebaseConnected === null ? (
    <Navigate to={redirectPath} replace />
  ) : null;
};
