import PreviewKudos from './../../../../public/PreviewKudos.svg';
import PreviewGrateful from './../../../../public/PreviewGratitude.svg';
import PreviewMindful from './../../../../public/PreviewMidfulness.svg';
import PreviewIntentional from './../../../../public/PreviewIntention.svg';
import PreviewAffirmational from './../../../../public/PreviewAffirmation.svg';

type CardPreviewProps = { type: string; title: string };

export const CardPreview = ({ type, title }: CardPreviewProps) => {
  switch (type) {
    case 'affirmation':
      return (
        <div className="relative">
          <img
            src={PreviewAffirmational}
            alt="Affirmation Card"
            className="w-[350px] h-[450px]"
          />
          <div className="absolute bg-white z-10 w-72 h-52 top-44 left-8 flex items-center flex-col px-4 text-center">
            <p className="text-md py-4 text-[#521C00] font-semibold">{title}</p>
          </div>
          <div className="w-40 h-10 absolute z-20 bottom-5 right-24 bg-white mx-auto" />
        </div>
      );
    case 'mindfulness':
      return (
        <div className="relative">
          <img
            src={PreviewMindful}
            alt="Mindful Card"
            className="w-[350px] h-[450px]"
          />
          <div className="absolute bg-white z-10 w-72 h-52 top-44 left-8 flex items-center flex-col px-4 text-center">
            <p className="text-md py-4 text-[#55724D] font-semibold">{title}</p>
          </div>
          <div className="w-40 h-10 absolute z-20 bottom-5 right-24 bg-white mx-auto" />
        </div>
      );
    case 'gratitude':
      return (
        <div className="relative">
          <img
            src={PreviewGrateful}
            alt="Grateful Card"
            className="w-[350px] h-[450px]"
          />
          <div className="absolute bg-white z-10 w-72 h-52 top-44 left-8 flex items-center flex-col px-4 text-center">
            <p className="text-md py-4 text-[#1E3552] font-semibold">{title}</p>
          </div>
          <div className="w-40 h-10 absolute z-20 bottom-5 right-24 bg-white mx-auto" />
        </div>
      );
    case 'intention':
      return (
        <div className="relative">
          <img
            src={PreviewIntentional}
            alt="Intention Card"
            className="w-[350px] h-[450px]"
          />
          <div className="absolute bg-white z-10 w-72 h-52 top-44 left-8 flex items-center flex-col px-4 text-center">
            <p className="text-md py-4 text-[#67266F] font-semibold">{title}</p>
          </div>
          <div className="w-40 h-10 absolute z-20 bottom-5 right-24 bg-white mx-auto" />
        </div>
      );
    case 'kudos':
      return (
        <div className="relative">
          <img
            src={PreviewKudos}
            alt="Kudos Card"
            className="w-[350px] h-[450px]"
          />
          <div className="absolute bg-white z-10 w-72 h-52 top-44 left-8 flex items-center flex-col px-4 text-center">
            <p className="text-md py-4 text-[#4f5071] font-semibold">{title}</p>
          </div>
          <div className="w-40 h-10 absolute z-20 bottom-5 right-24 bg-white mx-auto" />
        </div>
      );
    default:
      return null;
  }
};
