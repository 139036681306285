import { useState, useEffect } from 'react';

const useAudioPreview = () => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [source, setSource] = useState<AudioBufferSourceNode | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTrackId, setCurrentTrackId] = useState<string | null>(null);

  useEffect(() => {
    const newAudioContext = new AudioContext();
    setAudioContext(newAudioContext);

    return () => {
      newAudioContext.close();
    };
  }, []);

  useEffect(() => {
    if (source) {
      source.onended = () => {
        setIsPlaying(false);
      };
    }
  }, [source]);

  const playAudio = (audioArrayBuffer: ArrayBuffer | null, trackId: string) => {
    if (audioContext) {
      setCurrentTrackId(trackId);

      if (audioArrayBuffer === null) {
        if (source) {
          source.start();
          setIsPlaying(true);
        }
        return;
      }

      audioContext.decodeAudioData(audioArrayBuffer).then((buffer) => {
        if (source) {
          source.stop();
        }

        const newSource = audioContext.createBufferSource();
        newSource.buffer = buffer;
        newSource.connect(audioContext.destination);
        newSource.start();
        setSource(newSource);
        setIsPlaying(true);
      });
    }
  };

  const stopAudio = () => {
    if (source) {
      source.stop();
      setSource(null);
      setIsPlaying(false);
    }
  };

  return { playAudio, stopAudio, isPlaying, currentTrackId };
};

export default useAudioPreview;
