import { Column } from 'react-table';
import { MediaFilesTableRow } from '../../../lib/types/tables';

export const columnList: Column<MediaFilesTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id', // accessor is the "key" in the data
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  {
    Header: 'TYPE',
    accessor: 'type',
  },
  {
    Header: 'SIZE',
    accessor: 'size',
  },
  {
    Header: 'DURATION',
    accessor: 'audioDuration',
  },
  {
    Header: 'DURATION TEXT',
    accessor: 'durationText',
  },
  {
    Header: 'DATE UPLOAD',
    accessor: 'createdAt',
  },
];
