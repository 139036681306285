import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  HiArrowLeft,
  HiOutlinePlay,
  HiOutlineStop,
  HiOutlineTrash,
} from 'react-icons/hi';
import { showToast } from 'lib/toast';
import { MediaFile, mediaFilesService } from 'lib/services/mediaFilesServices';
import { MediaFileInfoTab } from '../modalTabsTemplates/MediaFileInfoTab';
import { humanFileSize } from 'lib/utils/humanFileSize';
import { formatDateWithTime, formatSeconds } from 'lib/utils/formatDate';
import { FileInfoList } from './FileInfo';
import useAudioPreview from 'lib/hooks/useAudioPreview';

export type MediaFileInfoModalProps = {
  readonly fileId: string;
  readonly title?: string;
  readonly closeModal: () => void;
  readonly onDelete: (id: string) => void;
  readonly onUpdate: (file: MediaFile) => void;
};

const infoTabs: { [x: string]: string } = {
  INFO: 'Info',
};

export const MediaFileInfoModal: React.FC<MediaFileInfoModalProps> = ({
  fileId,
  closeModal,
  onUpdate,
  onDelete,
}) => {
  const { getFileById, getFileDownloadById } = mediaFilesService();
  const { playAudio, isPlaying, stopAudio } = useAudioPreview();
  const [currentTab, setCurrentTab] = useState(infoTabs.INFO);
  const [fileInfo, setFileInfo] = useState<
    Partial<MediaFile> & { publicUrl: string }
  >({
    title: '-',
    type: '-',
    fileSize: 0,
    createdAt: '-',
    audioDuration: 0,
    durationText: '',
    publicUrl: '-',
  });

  const getFullMediaFileInfo = async () => {
    try {
      const mediaFileInfoResponse = await getFileById(
        fileId.replace('/files/', '').replace('/download', '')
      );
      const mediaFileData = mediaFileInfoResponse.data;

      setFileInfo({
        ...mediaFileData,
        fileSize: humanFileSize(mediaFileData?.fileSize),
        createdAt: mediaFileData.createdAt
          ? formatDateWithTime(mediaFileData?.createdAt)
          : '-',
      });

      if (mediaFileData?.title) {
        setValue('title', mediaFileData.title);
      }
      if (mediaFileData?.durationText) {
        setValue('durationText', mediaFileData.durationText);
      }
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot access media file info',
      });
    }
  };

  const { register, setValue, formState, handleSubmit, setError } =
    useForm<MediaFile>({
      mode: 'onChange',
    });

  const onSubmit = (updatedFile: MediaFile) => {
    if (!updatedFile.title) {
      setError('title', { message: 'Title field is required' });
      return;
    }
    if (
      updatedFile?.durationText?.length &&
      updatedFile?.durationText.length > 255
    ) {
      setError('durationText', {
        message: 'Length must not exceed 255 characters.',
      });
      return;
    }
    if (updatedFile?.title?.length && updatedFile?.title.length > 255) {
      setError('title', { message: 'Length must not exceed 255 characters.' });
      return;
    }

    onUpdate(updatedFile);
    closeModal();
  };

  const fileInfoFields = useMemo(
    () => [
      { label: 'ID', value: fileId.split('/')[2] },
      { label: 'Type', value: fileInfo?.type },
      {
        label: 'Duration',
        value: fileInfo?.audioDuration
          ? formatSeconds(fileInfo.audioDuration)
          : '-',
      },
      { label: 'Size', value: fileInfo?.fileSize },
      { label: 'Upload date', value: fileInfo?.createdAt },
    ],
    [fileInfo, fileId]
  );

  const onPlayClick = async (id: string) => {
    const fileResponse = await getFileDownloadById(id);
    const audioArrayBuffer: ArrayBuffer = fileResponse.data;

    playAudio(audioArrayBuffer, id);
  };

  useEffect(() => {
    getFullMediaFileInfo();
  }, []);

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex justify-between items-start px-5 rounded-t relative">
        <button
          type="button"
          className="absolute left-3 top-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => closeModal()}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        <div className="text-sm text-gray-800 flex absolute top-5 right-3 space-x-2">
          {isPlaying ? (
            <HiOutlineStop
              className="w-5 h-5"
              onClick={() => stopAudio()}
            />
          ) : (
            <HiOutlinePlay
              className="w-5 h-5"
              onClick={() => onPlayClick(fileInfo.publicUrl)}
            />
          )}
          <HiOutlineTrash
            className="w-5 h-5"
            onClick={() => onDelete(fileId)}
          />
        </div>
      </div>
      <FileInfoList infoFields={fileInfoFields} />
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 px-1.5">
        <ul className="flex flex-wrap -mb-p text-sm ml-2">
          {Object.keys(infoTabs).map((tab) => (
            <li>
              <button
                onClick={() => {
                  setCurrentTab(infoTabs[tab]);
                }}
                className={classNames(
                  'inline-block mx-1.5 mt-2 pb-2 rounded-t-lg border-b hover:text-gray-600 hover:border-gray-300',
                  {
                    'text-gray-700 border-black': currentTab === infoTabs[tab],
                    'border-transparent': currentTab !== infoTabs[tab],
                  }
                )}
              >
                <p className="flex">{infoTabs[tab]}</p>
              </button>
            </li>
          ))}
        </ul>
      </div>
      {currentTab === infoTabs.INFO && (
        <MediaFileInfoTab
          register={register}
          formState={formState}
          isAudio={fileInfo?.type === 'audio'}
        />
      )}
      {formState?.isDirty && (
        <div className=" border-t h-20 flex justify-end items-center px-4">
          <button
            type="button"
            className="bg-blue-500 text-white p-2 rounded"
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};
