import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useStore } from 'lib/store';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

onAuthStateChanged(firebaseAuth, async (user) => {
  if (user) {
    const token = await user.getIdToken();

    localStorage.setItem('authToken', token);
    useStore.getState().updateUserProfile(user);
    useStore.getState().updateIsFirebaseConnected(true);
  } else {
    localStorage.removeItem('authToken');
    useStore.getState().updateUserProfile(null);
    useStore.getState().updateIsFirebaseConnected(null);
  }
});
