import { useCallback, useEffect, useState } from 'react';
import useExportExcel from './useExportExcel';
import { BackendError, TableSorting, UserFilters } from 'lib/types/entities';
import { shallow } from 'zustand/shallow';
import { useStore } from 'lib/store';
import { showToast } from 'lib/toast';
import { UseFormSetError } from 'react-hook-form';
import { baseBackendErrorHandler } from 'components/common/ErrorHandler';
import { userService } from 'lib/services/userServices';
import { UserProfileShape } from 'lib/types/store';
import { removeEmptyKeysFromObject } from 'lib/utils/removeEmptyKeys';

function useUserManagement() {
  const { getUsers, patchUser, getUsersExportExcel, deleteUser } =
    userService();
  const [currentSortingParams, setCurrentSortingParams] =
    useState<TableSorting | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDetailVisible, setIsDetailsVisible] = useState(false);
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] =
    useState<boolean>(false);
  const [isSearchDropdownVisible, setIsSearchDropdownVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<number>(0);
  const [currentFilterParams, setCurrentFilterParams] = useState<UserFilters>({
    signupOrigin: '',
    startDate: null,
    endDate: null,
    by_roles: null,
  });
  const [currentUserParams, setCurrentUserParams] = useState({
    page: 1,
    itemsPerPage: 30,
    totalItems: 0,
  });
  const getExportData = async () => {
    const exportResponse = await getUsersExportExcel({
      params: {
        firebaseAuthProvider: currentFilterParams.signupOrigin
          ? currentFilterParams.signupOrigin
          : undefined,
        createdAt:
          currentFilterParams.endDate && currentFilterParams.startDate
            ? {
                before: currentFilterParams.endDate.toISOString(),
                after: currentFilterParams.startDate.toISOString(),
              }
            : undefined,
        by_roles: currentFilterParams.by_roles
          ? currentFilterParams.by_roles
          : undefined,
        order: currentSortingParams || undefined,
      },
    });
    const fileName =
      exportResponse.headers['content-disposition'].split('filename=').at(-1) ||
      'Card.xlsx';

    return {
      binaryString: exportResponse.data,
      fileName,
    };
  };
  const { onExportButtonClick } = useExportExcel(getExportData);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const { updateUsersTableData } = useStore(
    (state) => ({
      updateUsersTableData: state.updateUsersTableData,
    }),
    shallow
  );

  const onSortClick = useCallback(
    (sorting: TableSorting) => setCurrentSortingParams(sorting),
    []
  );

  const getUsersRequest = async (newPage?: number) => {
    try {
      const usersDataParsed = await getUsers({
        params: {
          page: newPage || currentUserParams.page,
          itemsPerPage: currentUserParams.itemsPerPage,
          firebaseAuthProvider: currentFilterParams.signupOrigin
            ? currentFilterParams.signupOrigin
            : undefined,
          createdAt:
            currentFilterParams.endDate && currentFilterParams.startDate
              ? {
                  before: currentFilterParams.endDate.toISOString(),
                  after: currentFilterParams.startDate.toISOString(),
                }
              : undefined,
          by_roles: currentFilterParams.by_roles
            ? currentFilterParams.by_roles
            : undefined,
          order: currentSortingParams || undefined,
        },
      });
      const data = usersDataParsed.data['hydra:member'].map(
        ({
          email,
          phone,
          firebaseUser,
          roles,
          userProfile,
          provider,
          createdAt,
          isTester,
        }: UserProfileShape) => {
          return {
            id: firebaseUser || '',
            '@id': firebaseUser || '',
            email,
            phone,
            firebaseUser,
            roles,
            provider,
            userProfile,
            createdAt,
            isTester,
          };
        }
      );

      setCurrentUserParams({
        ...currentUserParams,
        page: newPage || currentUserParams.page,
        totalItems: usersDataParsed.data['hydra:totalItems'],
      });
      updateUsersTableData(data);
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access users',
      });
    }
  };
  const onUserDelete = async () => {
    try {
      await deleteUser({ firebaseId: selectedUserId });
      await getUsersRequest();
      setIsDetailsVisible(false);
      setIsDeleteModalVisible(false);
      showToast({
        type: 'success',
        title: 'Successfully deleted user',
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot delete user',
      });
    }
  };

  const onUserUpdate = async (
    updatedUser: UserProfileShape,
    setError: UseFormSetError<UserProfileShape>
  ) => {
    try {
      await patchUser({
        firebaseId: updatedUser.id,
        data: {
          ...updatedUser,
          userProfile: removeEmptyKeysFromObject(updatedUser?.userProfile),
        },
      });
      await getUsersRequest();
      setIsDetailsVisible(false);
    } catch (e) {
      const error = e as {
        data?: BackendError;
        status: number;
      };
      if (error.status === 422 && error?.data?.violations) {
        baseBackendErrorHandler(error.data?.violations, setError);
        return;
      }

      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot update user',
      });
    }
  };
  const closeModal = () => {
    setIsDetailsVisible(false);
  };

  const openModalAndSetId = useCallback((value: string) => {
    setSelectedUserId(value);
    setIsDetailsVisible(true);
  }, []);

  const updateCurrentFilters = (filters: UserFilters) => {
    setCurrentFilterParams(filters);
  };
  const onDeleteModalOpen = (id: string) => {
    setSelectedUserId(id);
    setIsDeleteModalVisible(true);
  };

  useEffect(() => {
    getUsersRequest();

    const activeFilterCount = Object.values({
      ...currentFilterParams,
      startDate: null,
    }).reduce((prevValue, value) => {
      return value ? prevValue + 1 : prevValue;
    }, 0);

    setActiveFilters(activeFilterCount);
  }, [currentFilterParams, currentSortingParams]);

  return {
    isDetailVisible,
    isFilterDropdownVisible,
    isSearchDropdownVisible,
    selectedUserId,
    activeFilters,
    currentUserParams,
    updateCurrentFilters,
    openModalAndSetId,
    closeModal,
    getUsersRequest,
    setIsSearchDropdownVisible,
    setIsFilterDropdownVisible,
    onUserDelete,
    onUserUpdate,
    onSortClick,
    onExportButtonClick,
    setSelectedUserId,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    onDeleteModalOpen,
  };
}

export default useUserManagement;
