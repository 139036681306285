type ColumnMap = Record<string, string>;

const columnMap: ColumnMap = {
  sequenceNumber: 'reference.sequenceNumber',
};

export const formatColumnName = (columnName: string | undefined): string => {
  if (!columnName) return '';

  if (columnMap.hasOwnProperty(columnName)) {
    return columnMap[columnName];
  }
  return columnName;
};
