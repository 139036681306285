import api, { PagingParams, axiosRequest } from './api';

export type ContentItem = {
  name: string;
  htmlContent: string;
  category: string;
};
export type GetContentItemByNameResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  id: string;
  htmlContent: string;
  category: string;
};

export type GetContentItemResponse = Partial<{
  '@context': string;
  '@id': string;
  '@type': string;
  name: string;
  htmlContent: string;
  createdAt: string;
  updatedAt: string;
  category: string;
}>;

type ContentItemsServices = {
  getContentItems: axiosRequest<PagingParams>;
  postContentItem: axiosRequest<PagingParams & { data: ContentItem }>;
  getContentItemStaticById: axiosRequest<{ itemId: string }>;
  getContentItemById: axiosRequest<{ itemId: string }>;
  patchContentItemById: axiosRequest<{
    itemId: string;
    data: ContentItem;
  }>;
  deleteContentItemById: axiosRequest<{ itemId: string }>;
  getFaqStatic: axiosRequest;
};

export const contentItemsServices: ContentItemsServices = {
  getContentItems: ({
    params = { page: 1, itemsPerPage: 30, publicVisibility: true },
  }) => {
    return api({}).get('/content_items', { params });
  },
  postContentItem: ({ data }) => {
    return api({}).post('/content_items', data);
  },
  getContentItemStaticById: ({ itemId }) => {
    return api({}).get(`/content_items/static/${itemId}`);
  },
  getFaqStatic: () => {
    return api({}).get(`/content_items/static/faq`);
  },
  getContentItemById: ({ itemId }) => {
    return api({}).get(`/content_items/${itemId}`);
  },
  patchContentItemById: ({ itemId, data }) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/content_items/${itemId}`, data);
  },
  deleteContentItemById: ({ itemId }) => {
    return api({}).delete(`/content_items/${itemId}`);
  },
};
