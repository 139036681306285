import classNames from 'classnames';
import { formatDate } from 'lib/utils/formatDate';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  HiChevronDown,
  HiExternalLink,
  HiOutlineCalendar,
} from 'react-icons/hi';
import 'react-datepicker/dist/react-datepicker.css';
import { TesterFilterOption, UserFilters } from 'lib/types/entities';
import { MdOutlinePsychology } from 'react-icons/md';
import { TESTER_ROLE_OPTIONS } from '../TabFiltersPopUp/data';

type UserFiltersPopUpProps = {
  isVisible: boolean;
  onClose: () => void;
  onChangeFilters: (filters: UserFilters) => void;
};

const ORIGIN_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Apple', value: 'apple.com' },
  { label: 'Google', value: 'google.com' },
  { label: 'Facebook', value: 'facebook.com' },
  { label: 'Email', value: 'password' },
];

export const UserFiltersPopUp: React.FC<UserFiltersPopUpProps> = ({
  isVisible,
  onClose,
  onChangeFilters,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentOrigin, setCurrentOrigin] = useState('');
  const [isTester, setIsTester] = useState<TesterFilterOption | null>(null);

  const onChange = (dates: (Date | null)[]) => {
    const [start, end] = dates;
    if (start?.getTime() === end?.getTime()){
      start?.setHours(0,0,0,0);
      end?.setHours(23,59,59,0)
      setStartDate(start);
      setEndDate(end);
      if (end) {
        setIsDatePickerOpen(false);
      }
      return;
    }
    start?.setHours(0,0,0,0);
    end?.setHours(0,0,0,0)
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setIsDatePickerOpen(false);
    }
  };
  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const handleResetFilters = () => {
    onChangeFilters({
      signupOrigin: '',
      startDate: null,
      endDate: null,
      by_roles: null,
    });
    setCurrentOrigin('');
    setStartDate(new Date());
    setEndDate(null);
    setIsTester(null);
    setIsDatePickerOpen(false);
    onClose();
  };
  const handleApplyFilters = () => {
    onChangeFilters({
      signupOrigin: currentOrigin,
      startDate,
      endDate,
      by_roles: isTester,
    });
    setIsDatePickerOpen(false);
    onClose();
  };

  return (
    <div
      id="dropdown"
      className={classNames(
        'absolute z-10 w-80 bg-white rounded shadow top-14 right-24 p-4',
        { hidden: !isVisible }
      )}
    >
      <p className="text-black font-medium pb-4">All Filters</p>
      <div
        className="flex justify-between items-center text-gray-400 font-light text-xs pb-4"
        onClick={() => onClose}
      >
        <div className="flex items-center">
          <HiExternalLink className="w-5 h-5 text-black" />
          <p className="text-black font-bold px-1 text-sm">Origin</p>
        </div>
        <select
          name="origin"
          value={currentOrigin}
          id="origin"
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !currentOrigin,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setCurrentOrigin(event.target.value)
          }
        >
          {ORIGIN_OPTIONS.map((origin) => (
            <option
              key={origin.label}
              value={origin.value}
            >
              {origin.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <HiOutlineCalendar className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Sign-Up Date</p>
        </div>
        <div
          className="w-40 bg-white flex items-center justify-end mr-1 cursor-pointer"
          onClick={handleClick}
        >
          <p className="px-2">
            {endDate && startDate
              ? formatDate(startDate) + ' - ' + formatDate(endDate)
              : 'All'}
          </p>
          <HiChevronDown className="w-4 h-4" />
        </div>
      </div>
      <div className="flex justify-between items-center text-gray-400 font-light text-xs mb-4">
        <div className="flex items-center">
          <MdOutlinePsychology className="w-4 h-4 text-black" />
          <p className="text-black font-bold px-2 text-sm">Tester role</p>
        </div>
        <select
          name="by_roles"
          value={isTester === null ? '' : isTester}
          className={classNames('bg-white mr-1 cursor-pointer', {
            'w-10': !isTester,
          })}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            if (event.target.value === '') {
              setIsTester(null);
            } else
              setIsTester(
                event.target.value === TesterFilterOption.TESTER
                  ? TesterFilterOption.TESTER
                  : TesterFilterOption.NO_TESTER
              );
          }}
        >
          {TESTER_ROLE_OPTIONS.map((option) => (
            <option
              key={option.label}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <button
        type="button"
        className="w-full text-gray-500 border border-gray-200 flex text-center justify-center rounded py-1 text-sm"
        onClick={handleResetFilters}
      >
        Default settings
      </button>
      <button
        type="button"
        className="w-full bg-blue-500 text-white border border-gray-200 flex text-center justify-center rounded py-1 my-2 text-sm"
        onClick={handleApplyFilters}
      >
        Apply
      </button>
      {isDatePickerOpen && (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          maxDate={new Date()}
          showYearDropdown
          showMonthDropdown
        />
      )}
    </div>
  );
};
