import { useEffect, useMemo, useState } from 'react';
import { HiArrowLeft, HiOutlineTrash } from 'react-icons/hi';
import { showToast } from 'lib/toast';
import { formatDateWithTime } from 'lib/utils/formatDate';
import {
  GetFeedbackByIdResponse,
  createFeedbackService,
} from 'lib/services/feedbackService';
import { FeedbackInfoList } from './FeedbackInfoList';
import { FeedbackInfoTab } from '../modalTabsTemplates/FeedbackInfoTab';
import { UserProfileShape } from 'lib/types/store';
import { userService } from 'lib/services/userServices';

export type FeedbackInfoModalProps = {
  readonly feedbackId: string;
  readonly closeModal: () => void;
  readonly onDelete: (id: string) => void;
};

export const FeedbackInfoModal: React.FC<FeedbackInfoModalProps> = ({
  feedbackId,
  closeModal,
  onDelete,
}) => {
  const { getFeedbackById } = createFeedbackService();
  const { getUser } = userService();

  const [feedbackInfo, setFeedbackInfo] = useState<
    GetFeedbackByIdResponse & { userInfo?: UserProfileShape | null }
  >({
    details: [],
    user: '-',
    userInfo: null,
    '@id': '',
    cancellationDate: '',
  });

  const getFeedbackInfo = async () => {
    try {
      const feedbackInfoResponse = await getFeedbackById(
        feedbackId?.replace('/user_subscription_cancellations/', '')
      );
      const feedbackData = feedbackInfoResponse.data;
      const userId = feedbackData.user?.replace('/users/', '');

      if (!userId) {
        setFeedbackInfo({
          ...feedbackData,
          user: 'Deleted user',
          userInfo: null,
          cancellationDate: feedbackData?.cancellationDate
            ? formatDateWithTime(feedbackData.cancellationDate)
            : '-',
        });
        return;
      }

      const userInfoResponse = await getUser({
        firebaseId: userId,
      });
      const userInfoData = userInfoResponse.data;
      setFeedbackInfo({
        ...feedbackData,
        user: userId,
        userInfo: userInfoData,
        cancellationDate: feedbackData?.cancellationDate
          ? formatDateWithTime(feedbackData.cancellationDate)
          : '-',
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot access feedback info',
      });
    }
  };

  const feedbackInfoFields = useMemo(
    () => [
      { label: 'ID', value: feedbackId.split('/')[2] },
      { label: 'USER ID', value: feedbackInfo?.user },
      {
        label: 'Cancellation date',
        value: feedbackInfo?.cancellationDate,
      },
      { label: 'Email', value: feedbackInfo.userInfo?.email },
      {
        label: 'Customer Since',
        value: feedbackInfo.userInfo?.createdAt
          ? formatDateWithTime(feedbackInfo.userInfo?.createdAt)
          : '',
      },
    ],
    [feedbackInfo, feedbackId]
  );

  useEffect(() => {
    getFeedbackInfo();
  }, []);

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex justify-between items-start px-5 rounded-t relative">
        <button
          type="button"
          className="absolute left-3 top-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => closeModal()}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        <div className="text-sm text-gray-800 flex absolute top-5 right-3 space-x-2">
          <HiOutlineTrash
            className="w-5 h-5"
            onClick={() => onDelete(feedbackId)}
          />
        </div>
      </div>
      <FeedbackInfoList infoFields={feedbackInfoFields} />
      <FeedbackInfoTab details={feedbackInfo.details} />
    </div>
  );
};
