import { ModalLayout, ModalMode } from '../../layouts/ModalLayout';
import { Portal } from './Portal';

export type ModalProps = React.PropsWithChildren<{
  readonly isModalVisible: boolean;
  readonly mode?: ModalMode;
}>;

export const Modal: React.FC<ModalProps> = ({
  children,
  isModalVisible,
  mode,
}) => {
  return isModalVisible ? (
    <Portal>
      <ModalLayout mode={mode}>{children}</ModalLayout>
    </Portal>
  ) : null;
};
