import { sendPasswordResetEmail } from 'firebase/auth';
import { showToast } from 'lib/toast';
import { firebaseAuth } from 'lib/utils/firebaseConfig';
import { useForm, SubmitHandler } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';

type ForgotPasswordFields = {
  email: string;
};

// TODO: add form validation like Yup

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, setError, formState, clearErrors } =
    useForm<ForgotPasswordFields>();

  const onSubmit: SubmitHandler<ForgotPasswordFields> = (data) => {
    const email = data.email.trim();

    if (!email) {
      setError('email', { message: 'Email is required field' });
      return;
    } else {
      clearErrors();
    }
    try {
      sendPasswordResetEmail(firebaseAuth, email);
    } finally {
      navigate('/');
      showToast({
        type: 'success',
        title: 'Reset email',
        message: 'Email successfully sent.',
      });
    }
  };

  return (
    <section className="shadow-md">
      <div className="px-12 py-10">
        <p className="text-gray-800 text-xl font-medium pb-8">
          Forgot password?
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col relative mb-12">
            <label
              htmlFor="email"
              className="text-gray-400 pb-2 text-sm "
            >
              Email
            </label>
            <input
              type="text"
              className="h-10 px-2 py-2 border-gray-200 border-solid border-2 rounded"
              {...register('email')}
              id="email"
            />
            {formState.errors.email && (
              <p className="text-red-500 text-xs pt-1">
                {formState.errors.email.message}
              </p>
            )}
          </div>
          <div className="flex flex-col justify-center">
            <button className="bg-blue-500 py-2 text-white rounded mb-4">
              Continue
            </button>
            <div className="flex justify-center items-center font-bold text-blue-500 text-center">
              <HiArrowNarrowLeft className="mr-2" />
              <NavLink
                to="/"
                className=""
              >
                Back to sign in
              </NavLink>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
