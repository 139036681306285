import api, { PagingParams, axiosRequest } from './api';

type FAQServiceShape = {
  getQuestions: axiosRequest<
    PagingParams & {
      publicVisibility?: boolean;
    }
  >;
  postQuestion: axiosRequest<{ data: Omit<FrequentlyAskedQuestion, '@id'> }>;
  getQuestionsStatic: axiosRequest<
    PagingParams & {
      publicVisibility: boolean;
    }
  >;
  getQuestionById: axiosRequest<{ questionId: string }>;
  patchQuestionById: axiosRequest<{
    questionId: string;
    data: PatchQuestionPayload;
  }>;
  deleteQuestionById: axiosRequest<{ questionId: string }>;
  patchQuestionSortingById: axiosRequest<{
    questionId: string;
    data: { direction: string; step: number };
  }>;
};
type PatchQuestionPayload = {
  question: string;
  answer: string;
};
export type FrequentlyAskedQuestion = {
  '@id': string;
  question: string;
  answer: string;
  publicVisibility: boolean;
  sorting?: number;
  createdAt?: string;
  updatedAt?: string;
};

export const FAQService: FAQServiceShape = {
  getQuestions: ({ headers = {}, params = { page: 1, itemsPerPage: 30 } }) => {
    return api({}).get('/frequently_asked_questions', { params });
  },
  postQuestion: ({ data }) => {
    return api({
      headers: {
        'Content-Type': 'application/ld+json',
      },
    }).post('/frequently_asked_questions', data);
  },
  getQuestionsStatic: ({ params }) => {
    return api({}).get(`/frequently_asked_questions/static`, { params });
  },
  getQuestionById: ({ questionId }) => {
    return api({}).get(`/frequently_asked_questions/${questionId}`);
  },
  patchQuestionById: ({ questionId, data }) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/frequently_asked_questions/${questionId}`, data);
  },
  patchQuestionSortingById: ({ questionId, data }) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/frequently_asked_questions/${questionId}/sorting`, data);
  },
  deleteQuestionById: ({ params, questionId }) => {
    return api({}).delete(`/frequently_asked_questions/${questionId}`, {
      params,
    });
  },
};
