type ColumnMap = Record<string, string>;

const columnMap: ColumnMap = {
  id: 'firebaseUser',
  provider: 'firebaseAuthProvider',
  name: 'userProfile.firstName',
  subscriptionStatus: 'userProfile.subscriptionStatus',
  subscriptionType: 'userProfile.subscriptionType',
  paymentStatus: 'userProfile.paymentStatus',
  subscriptionDate: 'userProfile.subscriptionDate',
};

export const formatColumnName = (columnName: string | undefined): string => {
  if (!columnName) return '';

  if (columnMap.hasOwnProperty(columnName)) {
    return columnMap[columnName];
  }
  return columnName;
};
