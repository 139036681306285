import { useEffect, useState } from 'react';
import { BackendError } from 'lib/types/entities';
import { showToast } from 'lib/toast';
import { useForm } from 'react-hook-form';
import { baseBackendErrorHandler } from 'components/common/ErrorHandler';
import { AxiosError } from 'axios';
import { configService } from 'lib/services/configService';
import { RemoteConfigSchema } from 'components/pages/RemoteConfigPage';
import { versionStringToNumber } from 'lib/utils/versionStringToNumber';

export const useRemoteConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentConfigId, setCurrentConfigId] = useState('');
  const { putConfig, getRemoteConfig } = configService();
  const { formState, handleSubmit, control, setValue, setError } =
    useForm<RemoteConfigSchema>({
      mode: 'onChange',
    });

  const onSubmit = async (data: RemoteConfigSchema) => {
    if (data.iosMinNotBlocked && data.iosLatest) {
      const iosMin = versionStringToNumber(data.iosMinNotBlocked);
      const iosActual = versionStringToNumber(data.iosLatest);

      if (iosMin > iosActual) {
        setError('iosMinNotBlocked', {
          message: 'Minimal version cannot be greater than actual',
        });
        return;
      }
    }
    if (data.androidMinNotBlocked && data.androidLatest) {
      const iosMin = versionStringToNumber(data.androidMinNotBlocked);
      const iosActual = versionStringToNumber(data.androidLatest);
      if (iosMin > iosActual) {
        setError('androidMinNotBlocked', {
          message: 'Minimal version cannot be greater than actual',
        });
        return;
      }
    }
    try {
      await putConfig(currentConfigId, data);
      showToast({
        type: 'success',
        title: 'Successfully updated remote config',
      });
    } catch (e) {
      const error = e as AxiosError & { data: BackendError };
      if (error.status === 422 && error.data.violations) {
        baseBackendErrorHandler(error.data.violations, setError);
        return;
      }
      showToast({
        type: 'error',
        title: 'Something went wrong when updating remote config',
      });
    }
  };

  const getCurrentVersions = async () => {
    const configResponse = await getRemoteConfig();
    const appVersions = configResponse.data.appVersions;

    setCurrentConfigId(appVersions.id);
    setValue('iosLatest', appVersions.iosLatest || '');
    setValue('iosMinNotBlocked', appVersions.iosMinNotBlocked || '');
    setValue('androidLatest', appVersions.androidLatest || '');
    setValue('androidMinNotBlocked', appVersions.androidMinNotBlocked || '');
    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentVersions();
  }, []);

  return {
    onSubmit,
    control,
    handleSubmit,
    formState,
    isLoading,
  };
};

export default useRemoteConfig;
