import { FaqQuestion } from 'lib/types/store';
import { HiOutlineTrash, HiPlus, HiMinus } from 'react-icons/hi';
import { ChangeEvent, useRef, useState } from 'react';
import { useStore } from 'lib/store';
import { shallow } from 'zustand/shallow';
import { showToast } from 'lib/toast';
import classNames from 'classnames';

type FAQCardProps = {
  faqContent: FaqQuestion;
  onDelete: (id: string) => void;
  onChange: (data: { question: string; answer: string; id: string }) => void;
  onOpen: (id: string) => Promise<FaqQuestion>;
};

export const FAQCard: React.FC<FAQCardProps> = ({
  faqContent,
  onChange,
  onDelete,
  onOpen,
}) => {
  const { question } = faqContent;
  const currentQuestionRef = useRef(question);
  const currentAnswerRef = useRef('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { faqQuestions, updateFaqQuestions } = useStore(
    (state) => ({
      faqQuestions: state.faqQuestions,
      updateFaqQuestions: state.updateFaqQuestions,
    }),
    shallow
  );

  const onDeleteCurrentQuestion = async (deleteId: string) => {
    try {
      await onDelete(deleteId);
      const filteredQuestions = faqQuestions.filter(
        (question) => question['@id'] !== deleteId
      );

      updateFaqQuestions([...filteredQuestions]);
      showToast({
        type: 'success',
        title: 'Success!',
        message: 'Question deleted successfully',
      });
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Cannot delete question',
      });
    }
  };
  const onChangeCurrentQuestion = async (
    newValue: string,
    isQuestion: boolean
  ) => {
    try {
      await onChange({
        id: faqContent['@id'],
        question: isQuestion ? newValue : faqContent.question,
        answer: isQuestion ? faqContent.answer : newValue,
      });
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Cannot update question',
      });
    }
  };
  const onOpenCard = async () => {
    const currentFaqData = await onOpen(faqContent['@id']);
    currentAnswerRef.current = currentFaqData.answer;
    setIsOpen(true);
  };
  return (
    <div key={faqContent['@id']} className="w-full">
      <div className="h-1 border-dashed border-t-2 w-full border-black my-2" />
      <div className="flex">
        <div className="w-full">
          <input
            type="text"
            placeholder="Enter question here..."
            maxLength={255}
            readOnly={!isOpen}
            defaultValue={currentQuestionRef.current}
            className={classNames(
              'w-full rounded mt-4 mb-14 select-none text-2xl font-medium',
              {
                'outline-none': !isOpen,
              }
            )}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              (currentQuestionRef.current = event.target.value)
            }
            onBlur={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.value &&
              isOpen &&
              onChangeCurrentQuestion(event.target.value, true)
            }
          />
          {isOpen && (
            <textarea
              placeholder="Enter answer here..."
              onBlur={(event) =>
                event.target.value &&
                isOpen &&
                onChangeCurrentQuestion(currentAnswerRef.current, false)
              }
              onChange={(event) => {
                currentAnswerRef.current = event?.target.value;
              }}
              defaultValue={currentAnswerRef.current}
              className="w-full rounded p-2 "
            />
          )}
        </div>
        <div className="w-10 flex space-x-2 justify-end pt-3 mx-2">
          {isOpen && (
            <HiOutlineTrash
              className="w-5 h-5"
              onClick={() => onDeleteCurrentQuestion(faqContent['@id'])}
            />
          )}
          {isOpen ? (
            <HiMinus className="w-5 h-5" onClick={() => setIsOpen(false)} />
          ) : (
            <HiPlus className="w-5 h-5" onClick={() => onOpenCard()} />
          )}
        </div>
      </div>
    </div>
  );
};
