import { useCallback, useEffect, useState } from 'react';
import useExportExcel from './useExportExcel';
import { BackendError, TabFilters, TableSorting } from 'lib/types/entities';
import { shallow } from 'zustand/shallow';
import {
  CardFile,
  GetCardsItem,
  GetCardsResponse,
  PostCardRequestData,
  cardsService,
} from 'lib/services/cardsServices';
import { useStore } from 'lib/store';
import { showToast } from 'lib/toast';
import { UseFormSetError } from 'react-hook-form';
import { baseBackendErrorHandler } from 'components/common/ErrorHandler';
import { AxiosError } from 'axios';
import { formatFiles } from 'lib/utils/formatFiles';

function useTabManagement(isDraft: boolean | null) {
  const {
    getCards,
    getCardsExportExcel,
    deleteCardById,
    patchCardById,
    postCardsApprove,
  } = cardsService();
  const { updateCardsTableData, cardsTableData } = useStore(
    (state) => ({
      updateCardsTableData: state.updateCardsTableData,
      cardsTableData: state.cardsTableData,
    }),
    shallow
  );
  const [isDetailVisible, setIsDetailsVisible] = useState<boolean>(false);
  const [isCreateTabModalVisible, setIsCreateTabModaVisible] =
    useState<boolean>(false);
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] =
    useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<number>(0);

  const [currentSortingParams, setCurrentSortingParams] =
    useState<TableSorting>({
      [isDraft ? 'reference.sequenceNumber' : 'sequenceNumber']: 'desc',
    });

  const [currentFilterParams, setCurrentFilterParams] = useState<TabFilters>({
    type: '',
    title: '',
    advanced: null,
    active: null,
  });
  const [currentPaginationParams, setCurrentPaginationParams] = useState({
    page: 1,
    itemsPerPage: 30,
    totalItems: 0,
  });
  const [selectedTabData, setSelectedTabData] = useState<{
    id: string;
    isCreatingFlow?: boolean;
  }>({ id: '' });
  const getExportData = async () => {
    const exportResponse = await getCardsExportExcel({
      params: {
        type: currentFilterParams?.type ? currentFilterParams?.type : undefined,
        title: currentFilterParams.title
          ? currentFilterParams.title
          : undefined,
        responseType: currentFilterParams.responseType
          ? currentFilterParams.responseType
          : undefined,
        active:
          typeof currentFilterParams.active === 'boolean'
            ? currentFilterParams.active
            : undefined,
        advanced:
          typeof currentFilterParams.advanced === 'boolean'
            ? currentFilterParams.advanced
            : undefined,
        frequency: currentFilterParams.frequency
          ? currentFilterParams.frequency
          : undefined,
        order: currentSortingParams,
      },
    });

    const fileName =
      exportResponse.headers['content-disposition'].split('filename=').at(-1) ||
      'User.xlsx';

    return {
      binaryString: exportResponse.data,
      fileName,
    };
  };
  const { onExportButtonClick } = useExportExcel(getExportData);
  const getCardsRequest = async (newPage?: number) => {
    try {
      const cardsResponse = await getCards({
        params: {
          page: newPage || currentPaginationParams.page,
          itemsPerPage: currentPaginationParams.itemsPerPage,
          type: currentFilterParams?.type
            ? currentFilterParams?.type
            : undefined,
          title: currentFilterParams.title
            ? currentFilterParams.title
            : undefined,
          responseType: currentFilterParams.responseType
            ? currentFilterParams.responseType
            : undefined,
          active:
            typeof currentFilterParams.active === 'boolean'
              ? currentFilterParams.active
              : undefined,
          advanced:
            typeof currentFilterParams.advanced === 'boolean'
              ? currentFilterParams.advanced
              : undefined,
          frequency: currentFilterParams.frequency
            ? currentFilterParams.frequency
            : undefined,
          order: currentSortingParams,
          draft: typeof isDraft === 'boolean' ? isDraft : undefined,
        },
      });
      const cardsData: GetCardsResponse = cardsResponse?.data;
      const data = cardsData['hydra:member'].map(
        ({
          frequency,
          sequenceNumber,
          similiatityGroup,
          ...cardProps
        }: GetCardsItem) => {
          const isResponseLimitSetuped =
            cardProps?.responseLimitType &&
            cardProps?.responseLimitNum &&
            cardProps?.responseLimitNum !== 0;

          const formattedLimit = isResponseLimitSetuped
            ? cardProps.responseLimitNum +
              ' ' +
              (cardProps.responseLimitNum === 1
                ? cardProps.responseLimitType
                : cardProps.responseLimitType + 's')
            : '-';
          return {
            ...cardProps,
            id: cardProps['@id'],
            sequenceNumber: Number(sequenceNumber),
            limit: formattedLimit,
            frequency: Number(frequency),
            similiatityGroup: Number(similiatityGroup),
          };
        }
      );

      setCurrentPaginationParams({
        ...currentPaginationParams,
        page: newPage || currentPaginationParams.page,
        totalItems: cardsData['hydra:totalItems'],
      });
      updateCardsTableData(data);
    } catch (error) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access cards',
      });
    }
  };

  const onCardDelete = async (deletingId: string) => {
    try {
      await deleteCardById(deletingId.replace('/cards/', ''));
      getCardsRequest(currentPaginationParams.page);
      closeDetailModal();
      showToast({
        type: 'success',
        title: 'Success',
        message: 'Card successfully deleted!',
      });
      return;
    } catch (error) {
      const errorResponse = error as AxiosError;
      if (errorResponse?.status === 422) {
        showToast({
          type: 'warning',
          title: 'Cant delete this card.',
          message: 'It is connected to active playlist.',
        });
        return;
      }
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong with deleting this card.',
      });
    }
  };

  const onCardUpdate = async (
    form: PostCardRequestData & { files: Array<CardFile> },
    setError: UseFormSetError<PostCardRequestData>,
    isClosing: boolean
  ) => {
    const {
      responseLimitType,
      responseLimitNum,
      frequency,
      files,
      similiatityGroup,
      ...otherFormFields
    } = form;

    try {
      await patchCardById(selectedTabData?.id.replace('/cards/', ''), {
        ...otherFormFields,
        frequency: Number(frequency),
        similiatityGroup: Number(form.similiatityGroup),
        files: form.mediaType === 'text' ? [] : formatFiles(files),
        ...(form.responseType === 'photo'
          ? {
              responseLimitType: null,
              responseLimitNum: null,
            }
          : { responseLimitType, responseLimitNum }),
      });
      showToast({
        type: 'success',
        title: 'Success',
        message: 'Card info successfully updated!',
      });
      await getCardsRequest(currentPaginationParams.page);
      if (isClosing) closeDetailModal();
    } catch (e) {
      const error = e as {
        data?: BackendError;
        status: number;
      };
      if (error.status === 422 && error?.data?.violations) {
        if (
          error.data['hydra:description'].includes('Card is used on play list')
        ) {
          showToast({
            type: 'warning',
            title: 'Cannot update card',
            message: 'You cant update card which is used in active playlist',
          });
          return;
        }
        baseBackendErrorHandler(error.data?.violations, setError);
      } else {
        showToast({
          type: 'error',
          title: 'Error',
          message: 'Cannot update card',
        });
      }
    }
  };
  const onApprove = async (
    form?: PostCardRequestData & {
      files: Array<CardFile>;
    },
    setError?: UseFormSetError<PostCardRequestData>,
    cardDraftIds?: string[]
  ) => {
    try {
      if (cardsTableData.length === 0) {
        showToast({
          type: 'error',
          title: 'Error',
          message: 'There are no draft cards to approve!',
        });
        return;
      }
      if (form && setError) {
        const {
          responseLimitType,
          responseLimitNum,
          frequency,
          similiatityGroup,
          files,
          ...otherFormFields
        } = form;

        await patchCardById(selectedTabData?.id.replace('/cards/', ''), {
          ...otherFormFields,
          frequency: Number(frequency),
          similiatityGroup: Number(form.similiatityGroup),
          files: form.mediaType === 'text' ? [] : formatFiles(files),
          ...(form.responseType === 'photo'
            ? {
                responseLimitType: null,
                responseLimitNum: null,
              }
            : { responseLimitType, responseLimitNum }),
        });
      }
      await postCardsApprove(cardDraftIds);
      await getCardsRequest(currentPaginationParams.page);
      showToast({
        type: 'success',
        title: 'Success',
        message:
          cardDraftIds && cardDraftIds.length > 0
            ? 'Successfully approved draft card!'
            : 'Successfully approved all draft cards!',
      });
      setIsDetailsVisible(false);
    } catch (e) {
      const error = e as AxiosError & { data: BackendError };

      if (error?.status === 422 && error?.data?.violations) {
        const uniqCards = new Map();
        error?.data?.violations.forEach((violation) => {
          const cardNumber = violation.message
            .replace(' is used on play list.', '')
            .replace('Card ', '');
          uniqCards.set(cardNumber, '');
        });
        const cardList = Array.from(uniqCards, ([name, _]) => name).join(',');

        showToast({
          type: 'error',
          title: 'Error',
          message: `Cannot approve ${
            cardList.includes(',')
              ? `cards with id - ${cardList} are already in active playlist`
              : `card with id - ${cardList} is already in active playlist`
          }`,
        });
      } else {
        showToast({
          type: 'error',
          title: 'Error',
          message: 'Cannot approve card',
        });
      }
    }
  };
  const closeDetailModal = () => {
    setIsDetailsVisible(false);
  };
  const onOpenCreateTabModal = () => {
    setIsCreateTabModaVisible(true);
  };
  const closeCreateTabModal = () => {
    setIsCreateTabModaVisible(false);
  };

  const openModalAndSetId = useCallback((value: string) => {
    setSelectedTabData({ id: value });
    setIsDetailsVisible(true);
  }, []);

  const updateCurrentFilters = (filters: TabFilters) => {
    setCurrentFilterParams(filters);
  };

  const onSortClick = useCallback((sorting: TableSorting) => {
    setCurrentSortingParams(sorting);
  }, []);

  useEffect(() => {
    if (!Object.values(currentSortingParams).length) return;

    getCardsRequest();

    const activeFilterCount = Object.values({
      ...currentFilterParams,
    }).reduce((prevValue, value, index) => {
      if (typeof value === 'boolean') return prevValue + 1;
      return value ? prevValue + 1 : prevValue;
    }, 0);

    if (activeFilterCount !== activeFilters) {
      setActiveFilters(activeFilterCount);
    }
  }, [currentFilterParams, currentSortingParams]);

  return {
    isCreateTabModalVisible,
    isDetailVisible,
    isFilterDropdownVisible,
    activeFilters,
    currentPaginationParams,
    selectedTabData,
    updateCurrentFilters,
    openModalAndSetId,
    setSelectedTabData,
    setIsDetailsVisible,
    getCardsRequest,
    setIsFilterDropdownVisible,
    closeDetailModal,
    closeCreateTabModal,
    onOpenCreateTabModal,
    onSortClick,
    onCardUpdate,
    onCardDelete,
    onExportButtonClick,
    cardsTableData,
    onApprove,
    currentSortingParams,
  };
}

export default useTabManagement;
