import api, { axiosRequest } from './api';

type SecurityService = {
  getProfile: axiosRequest;
  patchProfile: axiosRequest;
};

export const securityService: SecurityService = {
  getProfile: () => {
    return api({}).get(`/security/profile`);
  },
  patchProfile: () => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/security/profile`);
  },
};
