import { Column } from 'react-table';
import { UsersTableRow } from '../../../lib/types/tables';

export const columnList: Column<UsersTableRow>[] = [
  {
    Header: 'ID',
    accessor: 'id', // accessor is the "key" in the data
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'ROLE',
    accessor: 'roleId',
  },
  {
    Header: 'PHONE NUMBER',
    accessor: 'phone',
  },
  {
    Header: 'SUB TYPE',
    accessor: 'subscriptionType',
  },
  {
    Header: 'ORIGIN',
    accessor: 'provider',
  },
  {
    Header: 'SUB STATUS',
    accessor: 'subscriptionStatus',
  },
  {
    Header: 'PAY STATUS',
    accessor: 'paymentStatus',
  },
  {
    Header: 'BETA TEST',
    accessor: 'isTester',
  },
  {
    Header: 'SIGN UP DATE',
    accessor: 'createdAt',
  },
  {
    Header: 'SUB DATE',
    accessor: 'subscriptionDate',
  },
];
