import { FC } from "react";
import { Control, Controller } from 'react-hook-form';

type VersionInputProps = {
  control: Control<any, any>;
  name: string;
  label: string;
  inputStyle: string;
  labelStyle: string;
  controlStyle: string;
  errorStyle: string;
};

export const VersionInput: FC<VersionInputProps> = ({
  control,
  name,
  inputStyle,
  label,
  labelStyle,
  controlStyle,
  errorStyle,
}) => {

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, formState }) => (
          <div className={controlStyle}>
            <div style={{ position: 'relative' }}>
              <label
                htmlFor={name}
                className={labelStyle}
              >
                {label}
              </label>
              <input
                type='string'
                placeholder='1.0.0'
                pattern='^(\d{1,3}\.){2}\d{1,3}$'
                value={value}
                onChange={onChange}
                className={inputStyle}
              />
             
            </div>
            {formState?.errors?.[name] && (
              <p 
              className={errorStyle}
              >
                {`${formState.errors?.[name]?.message}`}
              </p>
            )}
          </div>
        )}
      />
    </>
  );
};
