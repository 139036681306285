import React from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { shallow } from 'zustand/shallow';
import { FeedbackTableRow } from '../../../lib/types/tables';
import { columnList } from './Data';
import { formatDateWithTime } from 'lib/utils/formatDate';

export type FeedbackTableProps = {
  onItemClick: (value: string) => void;
};

export const FeedbackTable: React.FC<FeedbackTableProps> = ({
  onItemClick,
}) => {
  const { feedbackTableData } = useStore(
    (state) => ({
      feedbackTableData: state.feedbackTableData,
    }),
    shallow
  );

  const data = React.useMemo<FeedbackTableRow[]>(
    () =>
      feedbackTableData.map(({ user, cancellationDate, id }) => {
        return {
          id: id?.replace('/user_subscription_cancellations/', ''),
          cancellationDate: cancellationDate
            ? formatDateWithTime(cancellationDate)
            : '-',
          user: user ? user.replace('/users/', '') : '-',
        };
      }),
    [feedbackTableData]
  );
  const columns = React.useMemo<Column<FeedbackTableRow>[]>(
    () => columnList,
    []
  );
  const initialState = { hiddenColumns: [] };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });

  return (
    <table
      {...getTableProps()}
      className="w-full text-xs"
    >
      <thead className="h-11 border-b border-gray-200 text-left ">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="text-gray-400 font-medium"
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className="h-10 px-4 w-1/3"
              >
                <div className="flex justify-start items-center">
                  {column.render('Header')}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="h-10 text-left border-b hover:bg-blue-100 w-1/3"
              onClick={(event) => {
                const target = event.target as Element;
                if (target.tagName !== 'INPUT' && target.tagName !== 'BUTTON') {
                  onItemClick(row.values.id);
                }
              }}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className="px-4 mr-auto max-w-[10px]"
                >
                  <div className="rounded-xl py-1 overflow-hidden text-ellipsis">
                    {cell.render('Cell')}
                  </div>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
