import { CardFile, CardFilesEdited } from "lib/services/cardsServices";

export const formatFiles = (files: CardFile[]) => {
    if (!files.length) return [];
    return files.map((file: CardFile) => {
      const fileId =
        typeof file.file === 'string' ? file?.file : file.file?.['@id'];
      return {
        file: fileId,
        title: file?.title,
        sorting: file?.sorting,
      } as CardFilesEdited;
    });
  };