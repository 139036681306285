import { MediaFile, mediaFilesService } from 'lib/services/mediaFilesServices';
import { MediaFileInput } from '../MediaFileInput';
import { useEffect, useState } from 'react';
import { CardFile } from 'lib/services/cardsServices';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { DropContainer } from 'components/common/DropContainer';
import { Direction } from 'lib/types/entities';
import useAudioPreview from 'lib/hooks/useAudioPreview';
import { showToast } from 'lib/toast';

export const DIRECTIONS: { [x: string]: Direction } = {
  down: 'down',
  up: 'up',
};

type CardFilesViewProps = {
  files: Array<CardFile>;
  onUpdateFile: (data: CardFile[]) => void;
  onReorderFile: (
    fileId: string,
    reorderRequest: { step: number; direction: Direction }
  ) => void;
};

export const CardFilesView: React.FC<CardFilesViewProps> = ({
  files,
  onUpdateFile,
  onReorderFile,
}) => {
  const { playAudio, stopAudio, isPlaying, currentTrackId } = useAudioPreview();
  const { getFiles } = mediaFilesService();
  const [currentFiles, setCurrentFiles] = useState<(CardFile | null)[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<MediaFile[]>([]);

  const connectFilesToAdd = async () => {
    try {
      const filesReponse = await getFiles({
        params: { page: 1, itemsPerPage: 1000, type: 'audio' },
      });

      setFilesToAdd(
        filesReponse?.data['hydra:member'].filter((file) =>
          file.fileExtension.includes('mp3')
        )
      );
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong with adding files',
      });
    }
  };

  const onUpdateCurrentFile = (newFile: CardFile) => {
    const isReplace = files.findIndex((file) => {
      return file?.file['@id'] === newFile.file['@id'];
    });

    if (isReplace >= 0) {
      const newFiles: Array<CardFile> = [...files];
      newFiles[isReplace] = { ...newFiles[isReplace], ...newFile };
      onUpdateFile(newFiles);
    } else {
      const highestSort = files.reduce((maxSorting, obj) => {
        return Math.max(maxSorting, obj.sorting);
      }, 0);
      onUpdateFile([...files, { ...newFile, sorting: highestSort + 1 }]);
    }
  };

  const onDeleteFile = (deleteId: string) => {
    const newFiles = [...files].filter((file) => {
      return file['@id'] !== deleteId;
    });
    onUpdateFile(newFiles);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    if (source.index === destination.index) return;
    const reorderRequest = {
      step: 0,
      direction: DIRECTIONS.down,
    };
    if (source.index !== destination.index) {
      if (source.index < destination.index) {
        reorderRequest.direction = DIRECTIONS.up;
        reorderRequest.step = destination.index - source.index;
      } else {
        reorderRequest.direction = DIRECTIONS.down;
        reorderRequest.step = source.index - destination.index;
      }
    }
    const sourceItem = files.find(
      (fileItem) => fileItem?.['@id'] === result.draggableId
    );

    const destionationItem = files.find(
      (_, index) => index === destination.index
    );

    if (sourceItem && destionationItem && sourceItem?.['@id']) {
      await onReorderFile(
        sourceItem?.['@id']
          .replace('/card_files/', '')
          .replace('/download', ''),
        reorderRequest
      );
      setCurrentFiles([]);
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    minWidth: isDragging ? '500px' : '100%',
    ...draggableStyle,
  });

  useEffect(() => {
    connectFilesToAdd();
  }, []);

  useEffect(() => {
    const newFiles = Array.from({ length: 3 }, (_, index) =>
      index < files.length ? files[index] : null
    );

    setCurrentFiles(newFiles);
  }, [files]);

  return (
    <div className="flex w-full flex-col flex-1 px-5 pt-4 bg-white min-w-[530px]">
      <div className="h-full max-w-[500px]">
        {
          <DragDropContext onDragEnd={onDragEnd}>
            <DropContainer
              droppableId="dropabble"
              direction="vertical"
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {currentFiles.map((dragItem, index) => {
                    return (
                      <Draggable
                        draggableId={dragItem?.['@id'] || String(index)}
                        index={index}
                        key={dragItem?.file?.['@id']}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={dragItem?.['@id'] || String(index)}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="flex"
                          >
                            <p
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              className="flex flex-col justify-start"
                            >
                              <MdDragIndicator className="w-5 h-5 mx-2 my-2" />
                            </p>
                            <MediaFileInput
                              connectedCardFile={currentFiles[index] || null}
                              fileIndex={index + 1}
                              suggestions={filesToAdd}
                              onUpdateFile={onUpdateCurrentFile}
                              onDeleteFile={onDeleteFile}
                              playAudio={playAudio}
                              currentTrackId={currentTrackId}
                              stopAudio={stopAudio}
                              isPlaying={isPlaying}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </DropContainer>
          </DragDropContext>
        }
      </div>
    </div>
  );
};
