import { PaginationFooter } from 'components/common/PaginationFooter';
import { MainLayout } from 'components/layouts/MainLayout';
import { ModalMode } from 'components/layouts/ModalLayout';
import { CardInfoModal } from 'components/modals/CardInfoModal';
import { Modal } from 'components/modals/Modal';
import { TabFiltersPopUp } from 'components/modals/TabFiltersPopUp';
import { CardsTable } from 'components/tables/CardsTable';
import useTabManagement from 'lib/hooks/useTabManagement';
import { useState } from 'react';
import { HiOutlineCheck } from 'react-icons/hi';

export const DraftManagementPage = () => {
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const {
    isDetailVisible,
    isFilterDropdownVisible,
    currentPaginationParams,
    selectedTabData,
    currentSortingParams,
    updateCurrentFilters,
    openModalAndSetId,
    getCardsRequest,
    setIsFilterDropdownVisible,
    closeDetailModal,
    onSortClick,
    onCardUpdate,
    onCardDelete,
    cardsTableData,
    onApprove,
  } = useTabManagement(true);

  const onApproveConfirmed = () => {
    onApprove();
    setIsApproveModalVisible(false);
  };
  return (
    <MainLayout>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> Draft Management</p>
          <div className="flex text-gray-500 relative">
            {!!cardsTableData.length && (
              <button
                className="border border-gray-100 rounded p-2 flex items-center text-sm mr-2 disabled:bg-gray-200"
                onClick={() => setIsApproveModalVisible(true)}
              >
                <HiOutlineCheck className="mx-1 fill-gray-50" />
                Approve all
              </button>
            )}
            <TabFiltersPopUp
              isVisible={isFilterDropdownVisible}
              onClose={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
              onChangeFilters={updateCurrentFilters}
            />
          </div>
        </div>
        {cardsTableData.length > 0 ? (
          <CardsTable
            onItemClick={openModalAndSetId}
            onSortClick={onSortClick}
            currentSorting={currentSortingParams}
            isDraft
          />
        ) : (
          <p className="flex justify-center py-4">No draft cards found</p>
        )}
        <PaginationFooter
          itemsPerPage={currentPaginationParams.itemsPerPage}
          currentPage={currentPaginationParams.page}
          totalItems={currentPaginationParams.totalItems}
          onPageClick={(newPage) => getCardsRequest(newPage)}
        />
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <CardInfoModal
          cardData={selectedTabData}
          closeModal={closeDetailModal}
          onDelete={onCardDelete}
          onUpdate={onCardUpdate}
          onApprove={onApprove}
          isDraft={true}
        />
      </Modal>
      <Modal mode={ModalMode.center} isModalVisible={isApproveModalVisible}>
        <div className="px-8 py-6">
          <p className="text-lg">Are you sure you want to approve all cards?</p>
          <div className="bg-white h-16 flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-blue-500 p-2 rounded"
              onClick={() => setIsApproveModalVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded"
              onClick={() => onApproveConfirmed()}
            >
              Approve
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};
