import { RecaptchaVerifier } from 'firebase/auth';
import { useStore } from 'lib/store';
import { SignInSteps } from 'lib/types/store';
import { firebaseAuth } from './firebaseConfig';

export const recaptchaVerifierInit = () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'normal',
        callback: function () {
          // reCAPTCHA solved, you can proceed with
          window.onRecaptchaSuccess();
          useStore.getState().updateCurrentSignInStep(SignInSteps.OTP);
        },
      },
      firebaseAuth
    );
  }
};
