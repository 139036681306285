import { MainLayout } from 'components/layouts/MainLayout';
import {
  ContentPageResponse,
  contentPagesServices,
} from 'lib/services/contentPagesServices';
import { useStore } from 'lib/store';
import { useEffect, useState } from 'react';
import { HiOutlineLink } from 'react-icons/hi';
import { shallow } from 'zustand/shallow';
import { TextEditor } from 'components/common/TextEditor';
import { showToast } from 'lib/toast';
import { FaqPage } from '../FaqPage';
import { ContentPagesList } from './ContentPagesList';
import { ModalMode } from 'components/layouts/ModalLayout';
import { Modal } from 'components/modals/Modal';
import { AxiosError } from 'axios';

export const ContentManagementPage = () => {
  const [isEditLinkModalVisible, setIsEditLinkModalVisible] = useState(false);
  const [currentContentPage, setCurrentContentPage] =
    useState<ContentPageResponse | null>(null);
  const [currentLink, setCurrentLink] = useState('');
  const [selectedPageId, setSelectedPageId] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentContent, setCurrentContent] = useState('');
  const { getContentPages, getContentPageById, patchContentPagesById } =
    contentPagesServices;
  const { contentPages, updateContentPages } = useStore(
    (state) => ({
      contentPages: state.contentPages,
      updateContentPages: state.updateContentPages,
    }),
    shallow
  );

  const getActualContentPages = async () => {
    try {
      const pages = await getContentPages({ publicVisibility: true });
      const formattedPages = pages.data['hydra:member'].map(
        (page: ContentPageResponse) => {
          return {
            urlPath: page.urlPath,
            contentTitle: page.contentTitle,
            publicVisibility: page.publicVisibility,
            createdAt: page.createdAt,
            '@id': page['@id'],
          };
        }
      );
      await updateContentPages(formattedPages);
      if (!selectedPageId) {
        setSelectedPageId(formattedPages[0]?.['@id']);
      }
    } catch (e) {
      showToast({
        type: 'error',
        title: `Error`,
        message: 'Cannot access users',
      });
    }
  };
  const updateCurrentContentPage = async () => {
    const idFetched =
      currentContentPage && selectedPageId && currentContentPage.urlPath;
    const isChanged =
      currentContentPage?.contentTitle !== currentTitle ||
      currentContent !== currentContentPage?.contentBody;

    if (idFetched && isChanged) {
      await patchContentPagesById({
        pageId: selectedPageId.replace('/content_pages/', ''),
        data: {
          urlPath: currentContentPage.urlPath,
          contentTitle: currentTitle,
          contentBody: currentContent,
          publicVisibility: currentContentPage.publicVisibility,
        },
      });
    }
  };

  const getCurrentPageActualContent = async () => {
    if (!selectedPageId || selectedPageId === 'faq') {
      setCurrentTitle('');
      setCurrentContent('');
      setCurrentLink('');
      return;
    }

    const contentPageResponse = await getContentPageById({
      pageId: selectedPageId.replace('/content_pages/', ''),
    });
    const currentPageContent = contentPageResponse.data;
    setCurrentTitle(currentPageContent.contentTitle);
    setCurrentContent(currentPageContent.contentBody);
    setCurrentLink(currentPageContent?.urlPath || '');
    setCurrentContentPage(currentPageContent);
  };

  const onChangeCurrentPageContent = async ({
    content,
  }: {
    content?: string;
  }) => {
    if (currentContentPage?.contentBody !== content && !!content) {
      setCurrentContent(content);
    }
  };

  const onUpdateLink = async () => {
    const linkRegExp = /^[a-z0-9_-]{3,}$/; // only lowercase, no spaces, numbers, letters and 3 or more characters

    if (!linkRegExp.test(currentLink)) {
      showToast({
        type: 'error',
        title: `Link slug is not valid`,
        message:
          'Link slug should have 3 characters or more , no spaces, no symbols.',
      });
      return;
    }
    try {
      if (currentContentPage && selectedPageId && currentContentPage.urlPath) {
        await patchContentPagesById({
          pageId: selectedPageId.replace('/content_pages/', ''),
          data: {
            urlPath: currentLink.trim().toLocaleLowerCase(),
            contentTitle: currentTitle,
            contentBody: currentContent,
            publicVisibility: currentContentPage.publicVisibility,
          },
        });
      }
      setIsEditLinkModalVisible(false);
      await getActualContentPages();
    } catch (e) {
      const axiosError = e as AxiosError & {
        response: { data: { 'hydra:description': string } };
      };
      if (
        axiosError.status === 422 &&
        axiosError?.response?.data?.['hydra:description']
      ) {
        if (
          axiosError?.response?.data['hydra:description'].includes('urlPath')
        ) {
          showToast({
            type: 'error',
            title: `Link with this slug already exists.`,
          });
          return;
        }
      }
    }
  };

  useEffect(() => {
    getActualContentPages();
  }, []);

  useEffect(() => {
    getCurrentPageActualContent();
  }, [selectedPageId]);

  useEffect(() => {
    if (currentContent) updateCurrentContentPage();
  }, [currentContent]);

  return (
    <MainLayout>
      <div className="h-20 w-full flex items-center justify-between border-b">
        <ContentPagesList
          pages={contentPages}
          selectedPageId={selectedPageId}
          onSelectPage={(id: string) => {
            setSelectedPageId(id);
          }}
        />
        {selectedPageId !== 'faq' && (
          <button
            className="border border-gray-100 rounded p-2 flex items-center text-sm min-w-[190px] mx-4"
            onClick={() => setIsEditLinkModalVisible(true)}
          >
            <HiOutlineLink className="fill-gray-100 mx-2" />
            Human readable link
          </button>
        )}
      </div>
      {selectedPageId !== 'faq' && currentContent && currentTitle && (
        <div className="flex-1 items-center justify-center min-w-[800px] max-w-4xl place-self-center">
          <div
            className="mb-6 pt-14 w-full"
            id="title"
          >
            <input
              type="text"
              className="max-w-3xl px-8 mx-auto h-10 overflow-y-visible w-full"
              value={currentTitle}
              placeholder="Enter title here..."
              onChange={(event) => {
                setCurrentTitle(event.target.value);
              }}
              onBlur={() => updateCurrentContentPage()}
            />
          </div>
          <div
            className="mb-4"
            id="fcontent"
          >
            <TextEditor
              className="max-w-3xl min-h-[500px] mx-auto h-10 overflow-y-visible -z-100 w-full"
              value={currentContent}
              placeholder="Enter topic here..."
              onBlur={(value, source, editor) => {
                onChangeCurrentPageContent({ content: editor.getHTML() });
              }}
            />
          </div>
        </div>
      )}
      {selectedPageId === 'faq' && <FaqPage />}
      <Modal
        mode={ModalMode.center}
        isModalVisible={isEditLinkModalVisible}
      >
        <div className="px-8 py-6">
          <p className="text-lg">Edit url link of current static page</p>
          <div className="w-full py-2">
            <label
              className="text-sm text-gray-400"
              htmlFor="urlPath"
            >
              Url path
            </label>
            <input
              type="text"
              maxLength={255}
              value={currentLink}
              onChange={(e) => setCurrentLink(e.target.value)}
              placeholder="Url path"
              className="w-full rounded p-2 selected:bg-white border-gray-200 border-2"
            />
          </div>
          <div className="bg-white h-16 flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-blue-500 p-2 rounded"
              onClick={() => {
                setCurrentLink(currentContentPage!.urlPath || '');
                setIsEditLinkModalVisible(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded"
              onClick={() => {
                onUpdateLink();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};
