import classNames from 'classnames';
import React from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { calculatePaginationArray } from './calculatePaginationArray';

type PaginationFooterProps = {
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageClick: (page: number) => void;
};

export const PaginationFooter = ({
  totalItems,
  currentPage,
  itemsPerPage,
  onPageClick,
}: PaginationFooterProps) => {
  const totalPages = Math.ceil(Number(totalItems) / Number(itemsPerPage));
  const paginationArray = calculatePaginationArray(totalPages, currentPage);

  return (
    <div className="flex space-between items-start p-3 border-t border-gray-100 w-full text-gray-500">
      {totalPages > 1 && (
        <div className="flex justify-between w-full">
          <div
            className="flex items-center px-4 py-2 cursor-pointer"
            onClick={() => {
              if (currentPage !== 1) onPageClick(currentPage - 1);
            }}
          >
            <HiArrowNarrowLeft className="w-5 h-5 mr-2" />
            Previous
          </div>
          <div className="flex items-center">
            {paginationArray.map((page: any, index: number) => (
              <React.Fragment key={index}>
                {page === -1 ? (
                  <p className="px-2">...</p>
                ) : (
                  <div
                    onClick={() => onPageClick(Number(page))}
                    className={classNames('px-2.5 py-1.5 cursor-pointer', {
                      'text-blue-500 bg-blue-50': currentPage === page,
                      'text-gray-500': currentPage !== page,
                    })}
                  >
                    {page}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div
            className="flex items-center px-4 py-2 cursor-pointer"
            onClick={() => {
              if (totalPages > currentPage) onPageClick(currentPage + 1);
            }}
          >
            Next
            <HiArrowNarrowRight className="ml-2 w-5 h-5 " />
          </div>
        </div>
      )}
    </div>
  );
};
