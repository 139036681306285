import { useStore } from 'lib/store';
import Spinner from './../../../public/Spinner.gif';

export const LoadingIndicator = () => {
  const { isFetching } = useStore((state) => ({
    isFetching: state.isFetching,
  }));

  return isFetching ? (
    <div className="fixed z-10 top-0 left-0 right-0 mx-auto bottom-0 flex items-center justify-center bg-gray-200 opacity-80 ">
      <img
        className="absolute z-10 w-20 h-20 opacity-100"
        src={Spinner}
        alt="loading..."
      />
    </div>
  ) : null;
};
