import { HiOutlinePlusCircle } from 'react-icons/hi';
import { useStore } from 'lib/store';
import { shallow } from 'zustand/shallow';
import { FAQCard } from './FAQCard/FAQCard';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { FAQService } from 'lib/services/FAQServices';
import { useEffect, useState } from 'react';
import { showToast } from 'lib/toast';
import {
  GetContentItemByNameResponse,
  contentItemsServices,
} from 'lib/services/contentItemsServices';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { DropContainer } from 'components/common/DropContainer';
import { DIRECTIONS } from '../TabManagementPage/CardFilesView';
import { MdDragIndicator } from 'react-icons/md';
import { FaqQuestion } from 'lib/types/store';

export const FaqPage = (): JSX.Element => {
  const {
    getQuestions,
    postQuestion,
    getQuestionById,
    deleteQuestionById,
    patchQuestionById,
    patchQuestionSortingById,
  } = FAQService;
  const [faqQuestions, setFaqQuestions] = useState([]);
  const [faqTitleData, setFaqTitleData] =
    useState<GetContentItemByNameResponse | null>(null);
  const { getFaqStatic, patchContentItemById } = contentItemsServices;
  const { faqContent, updateFaqContent, updateFaqQuestions } = useStore(
    (state) => ({
      faqContent: state.faqContent,
      updateFaqQuestions: state.updateFaqQuestions,
      updateFaqContent: state.updateFaqContent,
    }),
    shallow
  );

  const getActualFAQData = async () => {
    const mainContent = await getFaqStatic({});

    setFaqTitleData(mainContent.data);
    updateFaqContent(mainContent.data?.htmlContent);
    const actualQuestions = (await getQuestions({ publicVisibility: true }))
      .data['hydra:member'];
    setFaqQuestions(actualQuestions);
  };

  const onMainContentUpdate = async (htmlContent: string) => {
    try {
      if (faqTitleData) {
        await patchContentItemById({
          itemId: faqTitleData.id,
          data: { name: 'faq', htmlContent, category: faqTitleData.category },
        });
      }
    } catch (e) {
      showToast({
        type: 'success',
        title: 'Success!',
        message: 'Question deleted successfully',
      });
    }
  };

  const onDeleteFaq = async (deletingId: string) => {
    try {
      await deleteQuestionById({
        questionId: deletingId.replace('/frequently_asked_questions/', ''),
      });
      getActualFAQData();
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Cannot delete question',
      });
    }
  };

  const onChangeFaq = async (faqTopic: {
    id: string;
    question: string;
    answer: string;
  }) => {
    try {
      await patchQuestionById({
        questionId: faqTopic.id.replace('/frequently_asked_questions/', ''),
        data: { answer: faqTopic.answer, question: faqTopic.question },
      });
      getActualFAQData();
    } catch {
      showToast({
        type: 'error',
        title: 'Cannot edit question',
      });
    }
  };

  const onAddNewQuestion = async () => {
    try {
      await postQuestion({
        data: {
          question: 'Title',
          answer: 'Text',
          publicVisibility: true,
        },
      });
      getActualFAQData();
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Cannot add question',
      });
    }
  };

  const onOpenFaq = async (id: string) => {
    try {
      const faqData = await getQuestionById({
        questionId: id.replace('/frequently_asked_questions/', ''),
      });

      return faqData.data;
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Cannot add question',
      });
    }
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    if (source.index === destination.index) return;
    const reorderRequest = {
      step: 0,
      direction: DIRECTIONS.down,
    };
    if (source.index !== destination.index) {
      if (source.index < destination.index) {
        reorderRequest.direction = DIRECTIONS.up;
        reorderRequest.step = destination.index - source.index;
      } else {
        reorderRequest.direction = DIRECTIONS.down;
        reorderRequest.step = source.index - destination.index;
      }
    }
    const sourceItem = faqQuestions.find(
      (fileItem) => fileItem?.['@id'] === result.draggableId
    ) as FaqQuestion | undefined;

    const destionationItem = faqQuestions.find(
      (_, index) => index === destination.index
    ) as FaqQuestion | undefined;

    if (sourceItem && destionationItem && sourceItem?.['@id']) {
      const questionId = sourceItem['@id'].replace(
        '/frequently_asked_questions/',
        ''
      );
      await patchQuestionSortingById({
        questionId,
        data: reorderRequest,
      });
      updateFaqQuestions([]);
      getActualFAQData();
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    minWidth: isDragging ? '500px' : '100%',
    ...draggableStyle,
  });

  useEffect(() => {
    getActualFAQData();
  }, []);

  return (
    <div className="flex-1 items-center justify-center min-w-[800px] max-w-4xl place-self-center">
      <TextEditor
        className="max-w-3xl  mx-auto overflow-y-visible pt-14 mb-14"
        value={faqContent || undefined}
        placeholder="Enter faq content here..."
        onBlur={(prev, source, editor) => onMainContentUpdate(editor.getHTML())}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <DropContainer
          droppableId="dropabble"
          direction="vertical"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {faqQuestions.map((dragItem, index) => {
                return (
                  <Draggable
                    draggableId={dragItem?.['@id'] || String(index)}
                    index={index}
                    key={dragItem?.['@id']}
                  >
                    {(provided, snapshot) => (
                      <div
                        id={dragItem?.['@id'] || String(index)}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="flex"
                      >
                        <p
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          className="flex flex-col justify-start align-center pb-5 place-self-center"
                        >
                          <MdDragIndicator className="w-5 h-5 mx-2 my-2" />
                        </p>
                        <FAQCard
                          faqContent={dragItem}
                          key={dragItem['@id']}
                          onDelete={onDeleteFaq}
                          onChange={onChangeFaq}
                          onOpen={onOpenFaq}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </DropContainer>
      </DragDropContext>
      <div className="text-blue-500 py-6 flex items-center space-x-2 place-content-center">
        <div
          onClick={onAddNewQuestion}
          className="flex items-center cursor-pointer"
        >
          <HiOutlinePlusCircle className="w-4 h-4 mr-2" />
          <p>Add new</p>
        </div>
      </div>
    </div>
  );
};
