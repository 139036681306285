import React from 'react';
import { GetFeedbackItem } from 'lib/services/feedbackService';

export type SearchFeedbackResultsProps = {
  onItemClick: (value: string) => void;
  searchResultData: GetFeedbackItem[];
  searchBy: string;
  searchValue: string;
};

export const SearchFeedbackResults: React.FC<SearchFeedbackResultsProps> = ({
  onItemClick,
  searchResultData,
  searchBy,
  searchValue,
}) => {
  if (searchBy && searchValue && !searchResultData.length) {
    return <p className="py-3 px-6">No feedbacks found...</p>;
  }
  const highlightSearchValue = (text: string) => {
    const formattedText = text?.replace('/users/', '');
    const index = formattedText
      .toLowerCase()
      .indexOf(searchValue.toLowerCase());
    if (index !== -1) {
      return (
        <>
          {formattedText.slice(0, index)}
          <span className="font-bold">
            {formattedText.slice(index, index + searchValue.length)}
          </span>
          {formattedText.slice(index + searchValue.length)}
        </>
      );
    }
    return formattedText;
  };
  return searchResultData.length ? (
    <ul className="w-full max-h-60 overflow-y-auto">
      {searchResultData.map((feedback) => {
        if (!feedback?.user) return null;
        if (
          searchBy === 'user' &&
          !feedback[searchBy as keyof GetFeedbackItem]?.includes(searchValue)
        )
          return null;

        return (
          <li
            key={feedback['@id']}
            className="bg-white w-full text-black py-3 px-6 hover:bg-gray-50 rounded-lg"
            onClick={() =>
              onItemClick(
                feedback?.['@id']?.replace(
                  '/user_subscription_cancellations/',
                  ''
                )
              )
            }
          >
            {highlightSearchValue(
              `${feedback[searchBy as keyof GetFeedbackItem]}`
            )}
          </li>
        );
      })}
    </ul>
  ) : null;
};
