import { ModalLayout, ModalMode } from '../../layouts/ModalLayout';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPasswordPage = (): JSX.Element => {
  return (
    <ModalLayout mode={ModalMode.center}>
      <ForgotPasswordForm />
    </ModalLayout>
  );
};
