import { useForm, SubmitHandler, UseFormSetError } from 'react-hook-form';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { useStore } from 'lib/store';
import { shallow } from 'zustand/shallow';
import { SignInSteps } from 'lib/types/store';

export type OTPFields = {
  code: string;
};

// TODO: add form validation like Yup
type OTPFormProps = {
  onSubmitForm: (code: string, setError: UseFormSetError<OTPFields>) => void;
};
export const OTPForm: React.FC<OTPFormProps> = ({ onSubmitForm }) => {
  const { register, handleSubmit, setError, clearErrors, formState } =
    useForm<OTPFields>();
  const { updateCurrentSignInStep } = useStore(
    (state) => ({
      updateCurrentSignInStep: state.updateCurrentSignInStep,
    }),
    shallow
  );
  const onSubmit: SubmitHandler<OTPFields> = (data) => {
    const code = data.code.trim();
    if (!code) {
      setError('code', { message: 'Code is required field' });
      return;
    } else {
      clearErrors();
    }
    onSubmitForm(code, setError);
  };

  return (
    <section className="shadow-md">
      <div className="px-12 py-10">
        <p className="text-gray-800 text-xl font-medium pb-8">Code</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col relative mb-12">
            <label
              htmlFor="code"
              className="text-gray-400 pb-2 text-sm"
            >
              A code was sent to your phone number
            </label>
            <input
              type="password"
              id="code"
              className="h-10 px-2 py-2 border-gray-200 border-solid border-2 rounded"
              {...register('code')}
            />
            {formState.errors?.code && (
              <p className="text-red-500 text-xs mt-1">
                {formState.errors.code?.message}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-center">
            <button className="bg-blue-500 py-2 text-white rounded mb-4">
              Continue
            </button>
            <div className="flex justify-center items-center font-bold text-blue-500 text-center cursor-pointer">
              <HiArrowNarrowLeft className="mr-2" />
              <p onClick={() => updateCurrentSignInStep(SignInSteps.EMAIL)}>
                Back to sign in
              </p>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
