import { TableSorting } from 'lib/types/entities';
import api from './api';

export type MediaFile = {
  '@id': string;
  type: string;
  title: string;
  fileName: string;
  fileSize: number;
  fileExtension: string;
  updatedAt: string;
  createdAt: string;
  publicUrl: string;
  id?: string;
  audioDuration?: number;
  durationText?: string;
};
export type GetMediaFileResponse = {
  'hydra:member': MediaFile[];
  'hydra:totalItems': number;
};
export type GetFilesRequestParams = {
  params: {
    page: number;
    itemsPerPage: number;
    type?: string;
    order?: TableSorting;
  };
};

export const mediaFilesService = () => {
  const getFiles = async ({
    params = { page: 1, itemsPerPage: 30 },
  }: GetFilesRequestParams) => {
    return await api({}).get<GetMediaFileResponse>('/files', { params });
  };

  const postFile = async ({ data }: { data: FormData }) => {
    return await api({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).post('/files', data);
  };

  const getFileById = async (fileId: string) => {
    return await api({}).get(`/files/${fileId}`);
  };

  const getFileDownloadById = async (fileId: string) => {
    return await api({
      headers: { Authorization: undefined },
    }).get(fileId, { responseType: 'arraybuffer' });
  };

  const patchFileById = async (fileId: string, data: Partial<MediaFile>) => {
    return await api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/files/${fileId}`, data);
  };

  const deleteFileById = async (fileId: string) => {
    return await api({}).delete(`/files/${fileId}`);
  };

  return {
    deleteFileById,
    patchFileById,
    getFileDownloadById,
    getFileById,
    postFile,
    getFiles,
  };
};
