import classNames from 'classnames';
import { CustomSelect } from 'components/common/CustomSelect';
import { CardFile } from 'lib/services/cardsServices';
import { MediaFile, mediaFilesService } from 'lib/services/mediaFilesServices';
import { showToast } from 'lib/toast';
import { formatDateWithTime, formatSeconds } from 'lib/utils/formatDate';
import { humanFileSize } from 'lib/utils/humanFileSize';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { HiOutlinePause, HiOutlinePlay, HiOutlineTrash } from 'react-icons/hi';

type MediaFileInputProps = {
  connectedCardFile: CardFile | null;
  fileIndex: number;
  suggestions: MediaFile[];
  onUpdateFile: (file: CardFile) => void;
  onDeleteFile: (fileId: string) => void;
  playAudio: (arrayBuffer: ArrayBuffer | null, id: string) => void;
  stopAudio: () => void;
  isPlaying: boolean;
  currentTrackId: string | null;
};

export const MediaFileInput: React.FC<MediaFileInputProps> = ({
  connectedCardFile,
  fileIndex,
  suggestions,
  onUpdateFile,
  onDeleteFile,
  playAudio,
  stopAudio,
  isPlaying,
  currentTrackId,
}) => {
  const { getFileDownloadById } = mediaFilesService();
  const [newCardFile, setNewCardFile] = useState<CardFile | null>(null);
  const [newCardFileTitle, setNewCardFileTitle] = useState<string>('');
  const [newCardFileSorting, setCurrentSorting] = useState<number | null>(null);
  const [file, setFile] = useState<MediaFile | null>(null);
  const [isTitleError, setIsTitleError] = useState(false);

  const onChangeNewCardFile = (fileId: string) => {
    stopAudio();
    const newFile = suggestions.find((file) => file?.['@id'] === fileId);

    if (newFile) {
      setFile(newFile);
      setNewCardFile({
        '@id': connectedCardFile?.['@id'] || '',
        file: newFile,
        title: 'Title',
        sorting: newCardFileSorting || fileIndex + 1,
      });
      setNewCardFileTitle('Title');
      onUpdateFile({
        '@id': connectedCardFile?.['@id'] || '',
        file: newFile,
        title: 'Title',
        sorting: newCardFileSorting || fileIndex + 1,
      });
    }
  };

  const onBlurTitle = () => {
    if (!newCardFileTitle) {
      setIsTitleError(true);
      showToast({ type: 'error', title: 'Title is required field.' });
      return;
    }

    if (newCardFileTitle !== connectedCardFile?.title && connectedCardFile) {
      onUpdateFile({
        ...connectedCardFile,
        title: newCardFileTitle || 'Title',
        sorting: newCardFileSorting || fileIndex + 1,
      });
    }
  };
  const onPlayClick = async (id: string) => {
    if (currentTrackId === id) {
      playAudio(null, id);
      return;
    }

    const fileResponse = await getFileDownloadById(id);
    const audioArrayBuffer: ArrayBuffer = fileResponse.data;
    stopAudio();
    playAudio(audioArrayBuffer, id);
  };

  useEffect(() => {
    if (connectedCardFile) {
      setFile(connectedCardFile.file);
      setNewCardFileTitle(connectedCardFile.title);
      connectedCardFile?.sorting &&
        setCurrentSorting(connectedCardFile.sorting);
    }
  }, []);

  useEffect(() => {
    const isSameFile =
      newCardFile &&
      connectedCardFile?.file?.['@id'] !== newCardFile?.file?.['@id'];
    if (isSameFile && connectedCardFile) {
      onUpdateFile(newCardFile);
    }
  }, [newCardFile]);

  const suggestionFileOptions = useMemo(
    () =>
      suggestions.map((suggestionFile) => {
        const formattedTitle =
          suggestionFile.title.length > 20
            ? suggestionFile.title.slice(0, 10) +
              '...' +
              suggestionFile.title.slice(-10)
            : suggestionFile.title;
        return {
          label: `${formattedTitle}.${suggestionFile.fileExtension}`,
          value: suggestionFile?.['@id'],
        };
      }),
    [suggestions]
  );

  return (
    <div className="flex flex-col border rounded-lg mb-4 w-full">
      <div className="border-b-2">
        <div className="flex justify-between items-center p-2">
          <span className="text-sm">AudioFile № {fileIndex}</span>
          <div className="flex w-10 justify-between">
            {isPlaying && currentTrackId === file?.publicUrl ? (
              <HiOutlinePause onClick={() => stopAudio()} />
            ) : (
              <HiOutlinePlay
                onClick={() =>
                  file && file.publicUrl && onPlayClick(file.publicUrl)
                }
              />
            )}
            <HiOutlineTrash
              onClick={() => {
                if (connectedCardFile && connectedCardFile?.file?.['@id']) {
                  onDeleteFile(connectedCardFile?.['@id']);
                }
                setFile(null);
                setNewCardFileTitle('');
                stopAudio();
              }}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-4 pb-5 pt-2  space-y-4">
        <div>
          <p className="text-gray-500 text-sm pb-2">File</p>
          <CustomSelect
            value={file?.['@id']}
            title="File"
            options={suggestionFileOptions}
            name="fileName"
            onCustomChange={onChangeNewCardFile}
          />
        </div>
        {file && (
          <>
            <div className="flex flex-col">
              <input
                placeholder="Title"
                value={newCardFileTitle}
                className={classNames(
                  'rounded-md text-sm text-black font-semibold mb-2 p-1 placeholder:text-gray-200',
                  {
                    'border-red-500 border': isTitleError,
                  }
                )}
                onBlur={onBlurTitle}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setNewCardFileTitle(event.target.value);
                }}
              />
              <p className="text-blue-500 text-sm px-0.5">
                File title can be edited
              </p>
            </div>
            <div className="flex flex-col overflow-hidden">
              <p className="text-gray-500 text-sm pb-2">Duration Text</p>
              <p className="text-black text-sm px-0.5 text-ellipsis overflow-hidden max-w-[80%]">
                {file?.durationText || '-'}
              </p>
            </div>
            <div>
              <p className="text-sm text-black mb-1">Details</p>
              <div className="flex space-x-4">
                {file?.fileExtension && (
                  <p className="bg-gray-100 text-gray-800 rounded px-2 py-1">
                    .{file?.fileExtension}
                  </p>
                )}
                {file?.fileSize && (
                  <p className="bg-gray-100 text-gray-800 rounded px-2 py-1">
                    {humanFileSize(file?.fileSize)}
                  </p>
                )}
                {file?.updatedAt && (
                  <p className="bg-gray-100 text-gray-800 rounded px-2 py-1">
                    {formatDateWithTime(file?.updatedAt)}
                  </p>
                )}
                {file?.audioDuration && (
                  <p className="bg-gray-100 text-gray-800 rounded px-2 py-1">
                    {formatSeconds(file.audioDuration)}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
