import React, { memo } from 'react';
import { Column, useTable } from 'react-table';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { useStore } from '../../../lib/store';
import { shallow } from 'zustand/shallow';
import { MediaFilesTableRow } from '../../../lib/types/tables';
import { columnList } from './Data';
import classNames from 'classnames';
import { formatDateWithTime, formatSeconds } from 'lib/utils/formatDate';
import { humanFileSize } from 'lib/utils/humanFileSize';
import { TableSorting } from 'lib/types/entities';
import { formatColumnName } from './helpers';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';

export type MediaFilesTableProps = {
  onItemClick: (value: string) => void;
  onSortClick: (sorting: TableSorting) => void;
  currentSorting: TableSorting;
};

export const MediaFilesTable: React.FC<MediaFilesTableProps> = memo(
  ({ onItemClick, onSortClick, currentSorting }) => {
    const { mediaFilesTableData } = useStore(
      (state) => ({
        mediaFilesTableData: state.mediaFilesTableData,
        updateMediaFilesTableData: state.updateMediaFilesTableData,
      }),
      shallow
    );

    const data = React.useMemo<MediaFilesTableRow[]>(
      () =>
        mediaFilesTableData.map(
          ({
            id,
            type,
            size,
            title,
            createdAt,
            audioDuration,
            durationText,
          }) => {
            return {
              id: id || '-',
              size: humanFileSize(size) || '-',
              type: type || '-',
              audioDuration: audioDuration ? formatSeconds(audioDuration) : '-',
              title: title || '-',
              durationText: durationText || '-',
              createdAt: createdAt ? formatDateWithTime(createdAt) : '-',
            };
          }
        ),
      [mediaFilesTableData]
    );
    const columns = React.useMemo<Column<MediaFilesTableRow>[]>(
      () => columnList,
      []
    );
    const initialState = { hiddenColumns: ['id'] };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data, initialState });

    const onColumnClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const actualColumnName = formatColumnName(event.currentTarget.id);

      if (currentSorting[actualColumnName] === 'desc') {
        onSortClick({ [actualColumnName]: 'asc' });
      } else {
        onSortClick({
          [actualColumnName]: 'desc',
        });
      }
    };

    return (
      <table {...getTableProps()} className="w-full text-xs">
        <thead className="h-11 border-b border-gray-200 text-left ">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-gray-400 font-medium"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={classNames('h-10 px-4', {
                    'min-w-[300px]': column.id === 'title',
                    'w-[200px]':
                      column.id === 'durationText' ||
                      column.id === 'createdAt' ||
                      column.id === 'size' ||
                      column.id === 'type',
                  })}
                >
                  <div
                    id={column?.id}
                    className={classNames(
                      'flex justify-start items-center cursor-pointer',
                      {
                        'text-blue-500':
                          currentSorting[formatColumnName(column.id)],
                      }
                    )}
                    onClick={onColumnClick}
                  >
                    {column.render('Header')}
                    <p className="mx-1 h-4 relative">
                      <GoTriangleUp
                        className={classNames('w-2 h-2 top-[1px] absolute', {
                          hidden: currentSorting?.[formatColumnName(column.id)],
                        })}
                      />
                      <GoTriangleDown
                        className={classNames('w-2 h-2 bottom-[1px] absolute', {
                          hidden: currentSorting?.[formatColumnName(column.id)],
                        })}
                      />
                      <HiArrowDown
                        className={classNames('w-4 h-4 bottom-[1px] absolute', {
                          hidden:
                            currentSorting?.[formatColumnName(column.id)] !==
                            'desc',
                        })}
                      />
                      <HiArrowUp
                        className={classNames('w-4 h-4 bottom-[1px] absolute', {
                          hidden:
                            currentSorting?.[formatColumnName(column.id)] !==
                            'asc',
                        })}
                      />
                    </p>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="h-10 text-left border-b hover:bg-blue-100"
                onClick={(event) => {
                  const target = event.target as Element;
                  if (
                    target.tagName !== 'INPUT' &&
                    target.tagName !== 'BUTTON'
                  ) {
                    onItemClick(row.values.id);
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-4 mr-auto max-w-[10px]"
                  >
                    <div className="rounded-xl py-1 overflow-hidden text-ellipsis">
                      {cell.render('Cell')}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);
