import api from './api';
import { DAYS_OF_WEEK } from "lib/enums";

type PushConfigDay = {
  type: string;
  dayTitle: string;
  dayMessage: string;
  specificDay: boolean;
  deliveryTime: string;
  active: boolean;
  days: ReadonlyArray<{
    id: string;
    day: DAYS_OF_WEEK;
    specificDayTitle: string;
    specificDayMessage: string;
    specificDay: boolean;
    deliveryTime: string;
    active: boolean;
  }>
}

export type GetPushConfigResponse = {
  'hydra:member': ReadonlyArray<PushConfigDay>;
  'hydra:totalItems': number;
};

export const pushConfigService = () => {
  const getPushConfigRequest = async () => {
    return api({}).get<GetPushConfigResponse>(`/push_notification_settings`, {});
  };
  const patchPushConfigRequest = async (configId: string, data:any) => {
    return api({
      headers: { 'Content-type': 'application/merge-patch+json' },
    }).patch<GetPushConfigResponse>(`/push_notification_settings/${configId}`, data);
  };

  return { getPushConfigRequest, patchPushConfigRequest };
};
