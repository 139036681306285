import api, { PagingParams, axiosRequest } from './api';

export type ContentPage = {
  '@id': string;
  urlPath: string;
  contentTitle: string;
  contentBody: string;
  publicVisibility: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type GetContentPagesResponse = Omit<ContentPageResponse, '@context'>;

export type ContentPageResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  urlPath: string;
  contentTitle: string;
  contentBody: string;
  publicVisibility: boolean;
  createdAt: string;
  updatedAt: string;
};

type PatchContentPageByIdPayload = {
  urlPath: string;
  contentTitle: string;
  contentBody: string;
  publicVisibility: boolean;
};

type ContentPagesServices = {
  getContentPages: axiosRequest<PagingParams & { publicVisibility: boolean }>;
  postContentPages: axiosRequest<PagingParams & { data: ContentPage }>;
  getContentPagesStaticByPath: axiosRequest<{ urlPath: string }>;
  getContentPageById: axiosRequest<{ pageId: string }>;
  patchContentPagesById: axiosRequest<{
    pageId: string;
    data: PatchContentPageByIdPayload;
  }>;
  deleteContentPageById: axiosRequest<{ pageId: string }>;
};

export const contentPagesServices: ContentPagesServices = {
  getContentPages: ({
    params = { page: 1, itemsPerPage: 30, publicVisibility: true },
  }) => {
    return api({}).get('/content_pages', { params });
  },
  postContentPages: ({ data }) => {
    return api({}).post('/content_pages', data);
  },
  getContentPagesStaticByPath: ({ urlPath }) => {
    return api({}).get(`/content_pages/static/${urlPath}`);
  },
  getContentPageById: ({ pageId }) => {
    return api({}).get(`/content_pages/${pageId}`);
  },
  patchContentPagesById: ({ headers, pageId, data }) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/content_pages/${pageId}`, data);
  },
  deleteContentPageById: ({ pageId }) => {
    return api({}).delete(`/content_pages/${pageId}`);
  },
};
