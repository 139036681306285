export const calculatePaginationArray = (
  totalPages: number,
  currentPage: number
): number[] => {
  const paginationArray: number[] = [];
  const maxVisiblePages = 7;
  const ellipsis = -1;

  if (totalPages <= maxVisiblePages) {
    // If the total number of pages is less than or equal to the maximum visible pages,
    // show all the pages.
    for (let i = 1; i <= totalPages; i++) {
      paginationArray.push(i);
    }
  } else {
    const middlePage = Math.max(4, Math.min(totalPages - 3, currentPage));
    const startPage = middlePage - 3;

    // Add the first page.
    paginationArray.push(1);
    paginationArray.push(2);

    // Add ellipsis if needed.
    if (startPage > 1) {
      paginationArray.push(ellipsis);
    }

    // Add the page numbers between startPage and middlePage.
    for (let i = startPage + 2; i < middlePage; i++) {
      paginationArray.push(i);
    }

    // Add the current page.
    paginationArray.push(middlePage);

    // Add the page numbers between middlePage and the endPage.
    for (
      let i = middlePage + 1;
      i <= Math.min(totalPages - 2, middlePage + 1);
      i++
    ) {
      paginationArray.push(i);
    }

    // Add ellipsis if needed.
    if (totalPages - middlePage > 3) {
      paginationArray.push(ellipsis);
    }

    // Add the last page.
    paginationArray.push(totalPages - 1);
    paginationArray.push(totalPages);
  }

  return paginationArray;
};
